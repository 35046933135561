import React, { useState } from "react";
import style from "../../styles/faqs.module.css";
import { useTranslation } from "react-multi-lang";
import { Alert } from "react-bootstrap";

export default function FAQsHelp() {

  const d = useTranslation();
  const [show, setShow] = useState(false);

  var email = "";

  function updateInputValue(evt) {
    email = evt.target.value;
  }

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  function sendEmail(event) {
    
    if(validateEmail(email)) {
      // send email here;
      setShow(false);

      return true;
    } else {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 4000);
    }
  }

  return (
    <div className={`container ${style.helpContainer}`}>
    <div className={style.helpRow}>
      <div className={style.helpColunm}>
        <h4>{d("newFaqs.helpHeading")}</h4>
        <p>{d("newFaqs.helpDescription")}</p>
      </div>
      {show ? (
      <Alert
        variant="danger" // warning
        style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
      >
        {d("cta.error")}
      </Alert>
    ) : (
      ""
    )}
      <div className={style.helpColunm}>
        <form className={style.helpForm}>
          <input className={style.email} onChange={evt => updateInputValue(evt)}
          placeholder={d("newFaqs.emailPlacholder")}></input>
          <div className={style?.submit} onClick={e => sendEmail(e)}>
            <div className={style.arrowWrapper} >
              <img src="/images/arrow-send.svg"></img>
            </div>
          </div>
        </form>
        <div className={style.business}>{d("newFaqs.businessEmail")}</div>
      </div>
    </div>
  </div>
  );
}
