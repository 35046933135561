import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

// Styles
import style from "../../styles/ourStorySection.module.css";

export default function SiteSection(props) {
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
	const history = useHistory();
  const d = props.data;

  return (
    <section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className={`${style.subHeading} subHeading`}>
              {d("aboutus.story")}
            </h2>
            <div className={`mt-5 ${style.teamCarousel}`}>
              <div
                id="teamSlider"
                className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
                data-ride="carousel"
              >
              <div>
                  <h2 className={`${style.subHeadingSmall} subHeadingSmall`}>
                    {d("aboutus.longstoryTitle")}
                  </h2>
                  <h1 className={`${style.subTextSmall} subTextSmall mt-3`}>{d("aboutus.longstory1")}</h1>
                  <h1 className={`${style.subTextSmall} subTextSmall mt-3`}>{d("aboutus.longstory2")}</h1>
                  <h1 className={`${style.subTextSmall} subTextSmall mt-3`}>{d("aboutus.longstory3")}</h1>
                </div>
              </div>
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className={style.blob}>
                <path fill="#A7F0BA" d="M53.8,-40.7C65.3,-28.4,67.2,-6.3,62,13.2C56.7,32.7,44.4,49.5,25.6,61.9C6.9,74.2,-18.2,82,-34.9,73.5C-51.6,65.1,-59.9,40.3,-65.3,15.3C-70.6,-9.7,-73.1,-34.9,-61.7,-47.2C-50.3,-59.4,-25.2,-58.6,-2,-57C21.1,-55.4,42.3,-53,53.8,-40.7Z" transform="translate(100 100)" />
              </svg>

              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className={style.blob1}>
                <path fill="#A7F0BA" d="M47.7,-51.5C58.6,-36.9,61.9,-18.4,62.7,0.7C63.4,19.9,61.5,39.7,50.6,56.2C39.7,72.7,19.9,85.9,2.6,83.2C-14.6,80.6,-29.2,62.2,-45.4,45.7C-61.6,29.2,-79.4,14.6,-81.2,-1.9C-83.1,-18.3,-69,-36.6,-52.8,-51.3C-36.6,-65.9,-18.3,-76.8,0.1,-76.9C18.4,-76.9,36.9,-66.1,47.7,-51.5Z" transform="translate(100 100)" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </section>
	);
}
