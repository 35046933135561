import React from "react";
import style from "../styles/footer.module.css";
import headerstyle from "../styles/Header.module.css";
import { useTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";

export default function AuthFooter(props) {
  const data = useTranslation();
  return (
    <div className="container">
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ padding: "2em 0" }}
      >
        <div className="d-flex align-items-center">
          <div className={`dropdown ${headerstyle.dropdown}`}>
            <button
              className={`btn btn-outline-secondary ${style.languageBtn}`}
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {props.getLanguage === "gr" ? (
                <>
                  {" "}
                  <img src="/images/de.png" alt="" /> gr
                </>
              ) : (
                <>
                  <img src="/images/OIP.png" alt="" /> en
                </>
              )}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  props.setLanguage("en");
                }}
              >
                <img src="/images/OIP.png" alt="" /> en
              </a>
              <a
                className="dropdown-item"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  props.setLanguage("gr");
                }}
              >
                <img src="/images/de.png" alt="" /> gr
              </a>
            </div>
          </div>
          <a
            href="#"
            style={{
              color: "#454545",
              fontSize: "0.875em",
              fontWeight: "700",
              textDecoration: "none",
              marginLeft: "2.5em",
            }}
          >
            {data("auth.footer.text1")}
          </a>
        </div>
        <p
          style={{
            color: "#454545",
            fontSize: "0.875em",
            margin: "0",
          }}
        >
          {data("auth.footer.text2")}
        </p>
        <div className="links_footer">
          <a href="#">{data("auth.footer.text3")}</a>|
          <Link to="/cookies">{data("auth.footer.text4")}</Link>|
          <a href="#">{data("auth.footer.text5")}</a>|
          <a href="#">{data("auth.footer.text6")}</a>
        </div>
      </div>
    </div>
  );
}
