import React, { useEffect, useState } from "react";
import style from "../styles/api.module.css";
import CookiesTable from "../components/CookiesTable";
import data from "../data/cookiesTableData.json";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import CookiesModal from "../components/CookiesModal";
import CookiesPolicyModal from "../components/CookiesPolicyModal";

export default function APIDocumentation() {
  const d = useTranslation();
  const [show, setShow] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  useEffect(() => {
		document.title = `${d("tab.documentation")} - dioptify.com`;
	});

  const handleShow = () => setShow(true);

  return (
    <>
      <div className={`${style.cookiesSection} ${daynight ? style.nightMode : ""}`}>
        <div class="col-lg-12 mx-auto">
          <div className={style.heroSection}>
            <h1 class="mainHeading mb-1">{d("documentation.title")}</h1>
            <h4 className="commonText">v.2021/10/30</h4>
          </div>
        </div>

        <div style={{maxWidth:'2000px', margin:"auto"}}>
          <div class="col-lg-12 mx-auto">
              
            <div class="row">
              <div class="col-lg-2">
                <h6 class="subHeadingSmall mb-1">dioptify API</h6>

                <nav id="api-docs-nav" class="nav nav-pills flex-column">
                  <a class="nav-link active" href="#remove-background">Services</a>
                  <a class="nav-link" href="#easy-to-integrate">Easy to integrate</a>
                  <a class="nav-link" href="#get-started">Get started</a>
                  <a class="nav-link" href="#get-started">Videos</a>
                  <a class="nav-link" href="#sample-code">Sample code</a>
                  <a class="nav-link" href="#libraries">Libraries + Tools</a>
                  <a class="nav-link" href="#output-formats">Output Formats</a>
                  <a class="nav-link" href="#zip-format">How to use the ZIP format</a>
                  <a class="nav-link" href="#oauth-2">OAuth 2.0</a>
                  <a class="nav-link" href="#api-reference">API Reference</a>
                  <a class="nav-link" href="#rate-limit">Rate limit</a>
                  <a class="nav-link" href="#exponential-backoff">Exponential backoff</a>
                  <a class="nav-link" href="#api-changelog">API Changelog</a>
                </nav>

              </div>
              <div class="col-lg-10">
                <div className="mx-2">
                  <div className={style.card}>
                    <p>
                      Comming soon...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <CookiesPolicyModal show={show} setShow={setShow} />
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
