import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import {
    ReactCompareSlider,
    ReactCompareSliderImage,
  } from "react-compare-slider";

import { useSelector } from "react-redux";

// MaterialUI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import Filter3Icon from '@material-ui/icons/Filter3';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import EjectIcon from '@material-ui/icons/Eject';
import RateReviewIcon from '@material-ui/icons/RateReview';
import StyleIcon from '@material-ui/icons/Style';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import FilterFramesIcon from '@material-ui/icons/FilterFrames';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import MuiListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CommentIcon from '@material-ui/icons/Comment';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import FourKIcon from '@material-ui/icons/FourK';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ColorizeIcon from '@material-ui/icons/Colorize';
import FormatPaintIcon from '@material-ui/icons//FormatPaint';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import SendIcon from '@material-ui/icons/Send';
import CreateIcon from '@material-ui/icons/Create';
import SendOutlined from '@material-ui/icons/SendOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Styles
import style from "../../styles/services.module.css";


// instead of simple import we can manipulate it here
// https://codesandbox.io/s/listitem-selected-and-hover-3bp7r?fontsize=14&hidenavigation=1&theme=dark&file=/demo.js
var ListItem = withStyles({
    root: {
      "&:hover": {
        backgroundColor: "rgba(45, 255, 70, 0.1)",
        "& .MuiListItemIcon-root": {
          color: "white"
        }
      }
    },
    selected: {}
  })(MuiListItem);

export default function UploadImageAdmin(props) { // TODO: change to: ServiceList
    const [check, setCheck] = useState(false);
    const daynight = useSelector((state) => state.data.dayNight);
	const history = useHistory();
	const d = props.data;

    const [checked, setChecked] = React.useState([1]);
    const [open, setOpen] = React.useState(true);

    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(newChecked);
    };

    const clickToNS = () => {
        history.push({
            // pathname: '/ns', // commented due to changing base arch of service; from pytorch vanilla to zhong xina
            pathname: '/sns',
        });
    };

    const clickToNSV = () => {
        history.push({
            pathname: '/nsv',
        });
    };

    const clickToSR = () => {
        history.push({
            pathname: '/sr',
        });
    };
    
    const clickToDH = () => {
        history.push({
            pathname: '/dh',
        });
    };
    
    const clickToDB = () => {
        history.push({
            pathname: '/db',
        });
    };
    
    const clickToRB = () => {
        history.push({
            pathname: '/rbg',
        });
    };

    const clickToRBV = () => {
        history.push({
            pathname: '/rbgv',
        });
    };

    const clickToFI = () => {
        history.push({
            pathname: '/fi',
        });
    };

    const clickTo3DP = () => {
        history.push({
            pathname: '/3dp',
        });
    };

    const clickToHDR = () => {
        history.push({
            pathname: '/hdr',
        });
    };

    const clickToHDRV = () => {
        history.push({
            pathname: '/hdrv',
        });
    };

    const clickToII = () => {
        history.push({
            pathname: '/ii',
        });
    };

    const clickToVI = () => {
        history.push({
            pathname: '/vi',
        });
    };

    const clickToCL = () => {
        history.push({
            pathname: '/cl',
        });
    };

    const clickToDS = () => {
        history.push({
            pathname: '/ds',
        });
    };

    const clickToRF = () => {
        history.push({
            pathname: '/rf',
        });
    };

    const clickToFB = () => {
        history.push({
            pathname: '/fb',
        });
    };

    const clickToSD = () => {
        history.push({
            pathname: '/sd',
        });
    };

    const clickToPE = () => {
        history.push({
            pathname: '/pe',
        });
    };

    const clickToInfoSR = () => {
        history.push({
            pathname: '/services/sr/info',
        });
    };

    const clickToInfoDB = () => {
        history.push({
            pathname: '/services/db/info',
        });
    };

    const clickToInfoNST = () => {
        history.push({
            pathname: '/services/sns/info',
        });
    };

    const clickToInfoFI = () => {
        history.push({
            pathname: '/services/fi/info',
        });
    };

    const clickToInfoII = () => {
        history.push({
            pathname: '/services/ii/info',
        });
    };

    const clickToInfoHDR = () => {
        history.push({
            pathname: '/services/hdr/info',
        });
    };

    const clickToInfoRBG = () => {
        history.push({
            pathname: '/services/rb/info',
        });
    };

    const clickToInfoCL = () => {
        history.push({
            pathname: '/services/cl/info',
        });
    };

    const clickToInfoDS = () => {
        history.push({
            pathname: '/services/ds/info',
        });
    };

    const clickToInfoRF = () => {
        history.push({
            pathname: '/services/rf/info',
        });
    };

    const clickToInfoFB = () => {
        history.push({
            pathname: '/services/fb/info',
        });
    };

    const clickToInfoSD = () => {
        history.push({
            pathname: '/services/sd/info',
        });
    };

    const clickToInfoPE = () => {
        history.push({
            pathname: '/services/pe/info',
        });
    };

    const handleClick = () => {
        setOpen(!open);

        console.log("button clicked!");
    };

	const onDebugListToggle = (event) => {    
        setCheck(true);
    };
    
    const onDebugGridToggle = (event) => {
        setCheck(false);
    };

	return (
    <section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <h1 class="mainHeading mb-1">{d("team.servicetitle")}</h1>
          </div>
        </div>
      </div>

      <div className={`${style.buttons}`}>
        <Button
                className={`${style.btn} btn btn-outline-success`}
                onClick={(e) => {
                onDebugListToggle(e);
                }}
            >
            <div className={style.logo}>
                <i class="fa fa-list fa-2x"></i>
            </div>                
        </Button>
        <>&nbsp;</>
        <Button
                className={`${style.btn} btn btn-outline-success`}
                onClick={(e) => {
                onDebugGridToggle(e);
                }}
            >
            <div className={style.logo}>
                <i class="fa fa-th-large fa-2x"></i>
            </div>                
        </Button>         
      </div>

      {check
        ?
        <div className="container">
            <div className="row">
            <div className="col-lg-9 mx-auto">
                {/*
                <h2 className={`${style.subHeading} subHeading`}>
                {d("team.servicetitle")}
                </h2>
                */}
                <div className={`mt-5 ${style.teamCarousel}`}>
                <div
                    id="teamSlider"
                    className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
                    data-ride="carousel"
                >
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <Link to="/" className={`navbar-brand ${style.navbar_brand}`} href="/">
                        {daynight
                            ? <img className={`${style.navbarImg}`} src="/images/logo-and-icons/logo_new_white.png" alt="dioptify" />
                            : <img className={`${style.navbarImg}`} src="/images/logo-and-icons/logo_new.png" alt="dioptify" />
                        }
                    </Link>
                    <br /> <br />
                    <ListItem button onClick={clickToRB}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceRB")}/>
                    </ListItem>
                    {/*
                    <ListItem button onClick={clickToRBV}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <RemoveFromQueueIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceRBV")} />
                    </ListItem>
                    <ListItem button onClick={clickToRB}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <StyleIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("servicePNS")} />
                    </ListItem>
                    */}
                    <ListItem button onClick={clickToSR}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <AspectRatioIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceSR")} />
                    </ListItem>
                    {/*
                    <ListItem button onClick={clickToSR}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <FourKIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceSRV")} />
                    </ListItem>
                    */}
                    {/*
                    <ListItem button onClick={clickToDH}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <AcUnitIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceDH")} />
                    </ListItem>
                    */}
                    <ListItem button onClick={clickToDB}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <BlurOffIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceDB")} />
                    </ListItem>
                    {/*
                    <ListItem button onClick={clickToDB}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <ColorizeIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceCI")} />
                    </ListItem>
                    <ListItem button onClick={clickToDB}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <FormatPaintIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceCV")} />
                    </ListItem>
                    */}
                    {/*
                    <ListItem button onClick={clickToFI}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <FilterFramesIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceFI")} />
                    </ListItem>
                    */}
                    {/*
                    <ListItem button onClick={clickTo3DP}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <Filter3Icon />
                        </ListItemIcon>
                        <ListItemText primary={d("service3DP")} />
                    </ListItem>
                    */}
                    <ListItem button onClick={clickToHDR}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <LineStyleIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceHDR")} />
                    </ListItem>
                    {/*
                    <ListItem button onClick={clickToHDRV}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <LineWeightIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceHDRV")} />
                    </ListItem>
                    */}
                    <ListItem button onClick={clickToNS}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <CreateIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceNS")} />
                    </ListItem>
                    <ListItem button onClick={clickToCL}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <LineWeightIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceCL")} />
                    </ListItem>
                    <ListItem button onClick={clickToRF}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <FormatPaintIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceRF")} />
                    </ListItem>
                    <ListItem button onClick={clickToFB}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <FaceOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceFB")} />
                    </ListItem>
                    <ListItem button onClick={clickToFB}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <FilterFramesIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceII")} />
                    </ListItem>
                    <ListItem button onClick={clickToSD}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <ColorizeIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceSD")} />
                    </ListItem>
                    <ListItem button onClick={clickToPE}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <CommentIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("servicePE")} />
                    </ListItem>
                    <ListItem button onClick={clickToDS}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <EjectIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceDS")} secondary="Experimental"/>
                    </ListItem>
                    {/*
                    <ListItem button onClick={clickToNSV}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <SendOutlined />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceNSV")} />
                    </ListItem>
                    */}
                    {/*
                    <ListItem button onClick={clickToII}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <EjectIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceII")} />
                    </ListItem>
                    <ListItem button onClick={clickToVI}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <EjectIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceIV")} />
                    </ListItem>
                    */}
                    {/*
                    <ListItem button onClick={clickToRB}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <RateReviewIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceOPR")} />
                    </ListItem>
                    <ListItem button onClick={clickToRB}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <LowPriorityIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceDPI")} />
                    </ListItem>
                    <ListItem button onClick={clickToRB}>
                        <ListItemIcon style={{ color: "var(--dioptifyGreen)" }}>
                        <FingerprintIcon />
                        </ListItemIcon>
                        <ListItemText primary={d("serviceDPV")} />
                    </ListItem>
                    */}

                    {/*
                    <ListItem button onClick={handleClick}>
                        <ListItemIcon>
                        <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Remove Background Tets Expand" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        
                        <ListItem button>
                            <ListItemIcon>
                            <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary="Expanded" />
                        </ListItem>
                        
                        </List>
                    </Collapse>
                    */}
                </List>
                
                {/*
                <List>
                    {[0, 1, 2, 3].map((value) => {
                        const labelId = `checkbox-list-label-${value}`;

                        return (
                        <ListItem key={value} role={undefined} button onClick={handleToggle(value)}>
                            <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`Service ${value + 1}`} />
                            <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="comments">
                                <CommentIcon />
                            </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        );
                    })}
                </List>

                <List>
                    {[0, 1, 2, 3].map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value}`;
                    return (
                        <ListItem key={value} button>
                        <ListItemAvatar>
                            <Avatar
                            alt={`Avatar n°${value + 1}`}
                            src={`/static/images/avatar/${value + 1}.jpg`}
                            />
                        </ListItemAvatar>
                        <ListItemText id={labelId} primary={`Service ${value + 1}`} />
                        <ListItemSecondaryAction>
                            <Checkbox
                            edge="end"
                            onChange={handleToggle(value)}
                            checked={checked.indexOf(value) !== -1}
                            inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemSecondaryAction>
                        </ListItem>
                    );
                    })}
                </List>
                */}
                </div>
                {/*
                <img
                    className={style.sliderDotsImage}
                    src="/images/dots-img2.svg"
                    alt=""
                />
                <div className={style.sliderSquare}></div>
                */}
                </div>
            </div>
            </div>
            <div className="smText">
                {d("serviceList.subSoon")}
            </div>
        </div>
        :
        <div class="" style={{maxWidth:'1600px', margin:"auto"}}>
            <div class="col-lg-12 mx-auto">
            {/*
            <div class={style.heroSectionContents}>
                <h2 className={`${style.subHeading} subHeading`}>
                {d("aboutus.team")}
                </h2>
            </div>
            */}
            <div class="row">
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/rembg.jpg" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/rembg_remo.png"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceRB")}
                        </h2>
                        <p>{d("serviceList.subRB")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToRB(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoRBG(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>

                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/rembg.gif"
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/rembg_remo.gif"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceRBV")}
                        </h2>
                        <p>{d("serviceList.subRBV")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                clickToRBV(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-primary`}
                            onClick={(e) => {
                                clickToInfoRBG(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>
                */}
                {/* photorealistic style transfer */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/nsrt.png" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/nsrt_style.png" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("servicePNS")}
                        </h2>
                        <p>{d("serviceList.subPNS")}</p>
                        <br />
                        <a
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                onDebugGridToggle(e);
                            }}
                        >
                            {d("toService")}
                        </a>                        
                    </div>
                </div>
                */}
                
                {/* super resolution */}
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/zebra_intermediate_120x80.jpg"
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/zebra_ddsr_1_480x320.png"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceSR")}
                        </h2>
                        <p>{d("serviceList.subSR")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToSR(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoSR(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>

                {/* super resolution videos */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/srv_lr.gif" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/srv.gif" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceSRV")}
                        </h2>
                        <p>{d("serviceList.subSRV")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                clickToSR(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-primary`}
                            onClick={(e) => {
                                clickToInfoSR(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>
                */}

                {/* dezhazing */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/Rubber_Duck_(8374802487).jpg"
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/Rubber_Duck_8374802487_aLCSqEt.jpg"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceDH")}
                        </h2>
                        <p>{d("serviceList.subDH")}</p>
                        <br />
                        <a
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                clickToDH(e);
                            }}
                        >
                            {d("toService")}
                        </a>                        
                    </div>
                </div>
                */}

                {/* deblurring */}
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/flower_0.10_noisy.jpg" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/flower_0.10_noisy_c7r7OXM.jpg"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceDB")}
                        </h2>
                        <p>{d("serviceList.subDB")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToDB(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoDB(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>

                {/* colorisation */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/bw_img.jpg" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/bww_img.jpg" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceCI")}
                        </h2>
                        <p>{d("serviceList.subCI")}</p>
                        <br />
                        <a
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                onDebugGridToggle(e);
                            }}
                        >
                            {d("toService")}
                        </a>                        
                    </div>
                </div>
                */}

                {/* colorisation videos */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/bw.gif" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/bww.gif" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceCV")}
                        </h2>
                        <p>{d("serviceList.subCV")}</p>
                        <br />
                        <a
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                onDebugGridToggle(e);
                            }}
                        >
                            {d("toService")}
                        </a>                        
                    </div>
                </div>
                */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/I0_0.png" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/I0_slomo_clipped.gif" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            />   
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceFI")}
                        </h2>
                        <p>{d("serviceList.subFI")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                clickToFI(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-primary`}
                            onClick={(e) => {
                                clickToInfoFI(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>
                */}
                {/* 3d photos */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/3dp.jpg" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/3dp.gif" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("service3DP")}
                        </h2>
                        <p>{d("serviceList.sub3DP")}</p>
                        <br />
                        <a
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                clickTo3DP(e);
                            }}
                        >
                            {d("toService")}
                        </a>                        
                    </div>
                </div>
                */}

                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/hdr.png" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/hdr_done.png" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceHDR")}
                        </h2>
                        <p>{d("serviceList.subHDR")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToHDR(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoHDR(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/hdrv.gif" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/hdrv_done.gif" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceHDRV")}
                        </h2>
                        <p>{d("serviceList.subHDRV")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                clickToHDRV(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-primary`}
                            onClick={(e) => {
                                clickToInfoHDR(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>
                */}
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/Rubber_Duck_(8374802487).jpg"
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/Rubber_Duck_8374802487_KGIbMmy.jpg"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            />                            
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceNS")}
                        </h2>
                        <p>{d("serviceList.subNS")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToNS(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoNST(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>
                
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/marketing_bw.jpg"
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/marketing_colorized.png"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            />                            
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceCL")}
                        </h2>
                        <p>{d("serviceList.subCL")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToCL(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoCL(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/real_input_rf.jpg"
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/input_rf.png"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            />                            
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceRF")}
                        </h2>
                        <p>{d("serviceList.subRF")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToRF(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoRF(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/karan.jpg"
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/karan_anon.jpg"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            />                            
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceFB")}
                        </h2>
                        <p>{d("serviceList.subFB")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToFB(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoFB(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/bird-g907797f2c_640.jpg"
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/bird-g907797f2c_640_cleanup.jpg"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            />                            
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceII")}
                        </h2>
                        <p>{d("serviceList.subII")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToII(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoII(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/flowers-gbb2fc818f_640_640x427.jpg"
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/flowers-gbb2fc818f_640_cleanup_640x427.jpg"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            />                            
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceSD")}
                        </h2>
                        <p>{d("serviceList.subSD")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToSD(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoSD(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/ladybug-g5e57138c8_640.jpg"
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/ladybug-g5e57138c8_640_cleanup.jpg"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            />                            
                        </a>
                        <h2 class={style.subHeading}>
                            {d("servicePE")}
                        </h2>
                        <p>{d("serviceList.subPE")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToPE(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoPE(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <div className={`${style.ribbon} ${style.ribbonTopLeft}`}>
                            <span>Experimental</span>
                        </div>

                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/demo/1fromGOPR0950.png"
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/demo/output_1fromGOPR0950.png"
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            />                            
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceDS")}
                        </h2>
                        <p>{d("serviceList.subDS")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventure}`}
                            onClick={(e) => {
                                clickToDS(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-secondary ${style.buttonAdventureSecondary}`}
                            onClick={(e) => {
                                clickToInfoDS(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>

                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/nsv.gif" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/nsv_style.gif" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceNSV")}
                        </h2>
                        <p>{d("serviceList.subNSV")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                clickToNSV(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-primary`}
                            onClick={(e) => {
                                clickToInfoNST(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>
                */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/II.png" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/II_done.png" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceII")}
                        </h2>
                        <p>{d("serviceList.subII")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                clickToII(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-primary`}
                            onClick={(e) => {
                                clickToInfoII(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>
                */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard} style={{ display: "flex", flexDirection: "column" }}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/VI.gif" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/VI_done.gif" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceIV")}
                        </h2>
                        <p>{d("serviceList.subIV")}</p>
                        <br />
                        <a
                            style={{ marginTop: "auto", }}
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                clickToVI(e);
                            }}
                        >
                            {d("toService")} <i class="fas fa-magic"></i>
                        </a>
                        <a
                            style={{ marginTop: "10px", }}
                            className={`btn btn-outline-primary`}
                            onClick={(e) => {
                                clickToInfoII(e);
                            }}
                        >
                            Info <i class="fas fa-info-circle"></i>
                        </a>
                    </div>
                </div>
                */}
                {/* old photos revival */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/ROP.png" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/ROP_done.png" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceOPR")}
                        </h2>
                        <p>{d("serviceList.subOPR")}</p>
                        <br />
                        <a
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                onDebugGridToggle(e);
                            }}
                        >
                            {d("toService")}
                        </a>                        
                    </div>
                </div>
                */}

                {/* anonymisation images */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/dpi.jpg" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/dpi_done.jpg" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceDPI")}
                        </h2>
                        <p>{d("serviceList.subIA")}</p>
                        <br />
                        <a
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                onDebugGridToggle(e);
                            }}
                        >
                            {d("toService")}
                        </a>                        
                    </div>
                </div>
                */}

                {/* anonymisation videos */}
                {/*
                <div class="col-lg-3 mt-4">
                    <div class={style.toolsCard}>
                        <a class={style.imgLink}>
                            <ReactCompareSlider
                                style={{ borderRadius: "15px" }}
                                itemOne={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/dpv.gif" 
                                    alt="Image one"
                                />
                                }
                                itemTwo={
                                <ReactCompareSliderImage
                                    src="/servicesvisual/dpv_done.gif" 
                                    alt="Image two"
                                    style={{
                                    backgroundColor: "#fff",
                                    backgroundImage:
                                        "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)",
                                    backgroundSize: "59px",
                                    }}
                                />
                                }
                            /> 
                        </a>
                        <h2 class={style.subHeading}>
                            {d("serviceDPV")}
                        </h2>
                        <p>{d("serviceList.subVA")}</p>
                        <br />
                        <a
                            className={`btn btn-outline-success`}
                            onClick={(e) => {
                                onDebugGridToggle(e);
                            }}
                        >
                            {d("toService")}
                        </a>                        
                    </div>
                </div>
                */}
            </div>
            </div>
            <div className="smText">
                <br/>
                {d("serviceList.subSoon")}
            </div>
        </div>      
        }   
    </section>
	);
}
