import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import axiosInstance from '../axios';
import CookiesModal from "../components/CookiesModal";
import axios from 'axios';

import Loader from "../components/loaderComponent/Loader";
import UserForm from "../components/multiStepComponent/UserForm";
import DropFile from "../components/dropFileComponent/DropFile";

import CanvasDraw from "react-canvas-draw";

// Styles
import style from "../styles/debug.module.scss";
// import "../styles/debug.module.scss";

export default function Debug(props) {
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  const initialFormData = Object.freeze({
		email: '',
		username: '',
	});

  const initialFormDataCelery = Object.freeze({
		type: '',
	});

	const [formData, updateFormData] = useState(initialFormData);
	const [formDataCelery, updateFormDataCelery] = useState(initialFormDataCelery);

  const [check, setCheck] = useState(false);

	const onDebug = async(event) => {    
    updateFormData({
			...formData,
			email: "root@root.de",
			username: "root",
		});

    console.log("FORMDATA");
    console.log(formData);

    let formData2 = new FormData();
    formData2.append('email', uuidv4());
    formData2.append('username', uuidv4());

    await axiosInstance.get(`user/creditscore/`, formData2).then((res) => {
      // posts: res.data,
      console.log(res.data);
    })
    .catch((err) => {
      console.log("Unable to upload your file at the moment: " + err);
    });
	};


	const onDebugToken = async(event) => {    
    updateFormData({
			...formData,
			email: "root@root.de",
			username: "root",
		});


    console.log("FORMDATA");
    console.log(formData);

    let formData2 = new FormData();
    formData2.append('email', uuidv4());
    formData2.append('username', uuidv4());

    await axiosInstance.get(`user/apitoken/`, formData2).then((res) => {
      // posts: res.data,
      console.log(res.data);
    })
    .catch((err) => {
      console.log("Unable to upload your file at the moment: " + err);
    });
	};


	const onDebugCeleryShort = async(event) => {    
    updateFormDataCelery({
			...formDataCelery,
			type: "1",
		});

    console.log("FORMDATA");
    console.log(formDataCelery);

    let formData2 = new FormData();
    formData2.append('type', "1");
    console.log("FORMDATA REAL");
    console.log(formData2);

    const getStatus = async(taskID) => {
      await axios.get(`http://0.0.0.0:1337/celery/tasksdiopview/${taskID}/`, { task_id: taskID }).then((res) => {
        console.log(res.data);
        
        const taskStatus = res.data.task_status;
    
        if (taskStatus === 'SUCCESS' || taskStatus === 'FAILURE') return false;
        setTimeout(function() {
          getStatus(res.data.task_id);
        }, 1000); // this recalls the function recursive to see if the tasks has ended

      })
      .catch((err) => {
        console.log("Unable to get at the moment: " + err);
      });
    }

    await axios.post(`http://0.0.0.0:1337/celery/tasksdiopview/`, formDataCelery).then((res) => {
      console.log(res.data);
      
      getStatus(res.data.task_id);
    })
    .catch((err) => {
      console.log("Unable to upload your file at the moment: " + err);
    });


    /* */
	};

	const onDebugListToggle = (event) => {    
    setCheck(true);
	};

	const onDebugGridToggle = (event) => {
    setCheck(false);
	};



  let state = {
    color: "#ffc600",
    width: 400,
    height: 400,
    brushRadius: 10,
    lazyRadius: 12,
    backgroundImg: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
    imgs: [
      "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
      "https://i.imgur.com/a0CGGVC.jpg"
    ]
  };

  window.setInterval(() => {
    state.color = "#" + Math.floor(Math.random() * 16777215).toString(16);
  }, 2000);


  let saveableCanvas = null;
  let loadableCanvas = null


  return (
    <section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className={`${style.subHeading} subHeading`}>
              debugging!
            </h2>
            <div className={`mt-5 ${style.teamCarousel}`}>
              <div
                id="teamSlider"
                className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
                data-ride="carousel"
              >
              <div>
                <Link
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    onDebug(e);
                  }}
                >
                  Debug CreditScore
                </Link>
                <br /> <br />
                <Link
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    onDebugToken(e);
                  }}
                >
                  Debug APIToken
                </Link>
                <br /> <br />
                <Link
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    onDebugCeleryShort(e);
                  }}
                >
                  Debug Celery Task Short
                </Link>
                {/*<br/><br/><br/><br/><br/><br/>*/}
                <Loader></Loader>

                <div>
                  {/*<UserForm />*/}
                </div>                
              </div>

                {cookiesModal ? <CookiesModal /> : ""}
              </div>
            </div>







      <div>
        <h1>React Canvas Draw</h1>
        <h2>default</h2>
        <p>
          This is a simple <span>{`<CanvasDraw />`}</span> component with
          default values.
        </p>
        <p>Try it out! Draw on this white canvas:</p>
        <CanvasDraw onChange={() => console.log("onChange")} />
        <h2>Custom Brush-Color</h2>
        <p>
          Let's spice things up by using custom brush colors{" "}
          <span>{`<CanvasDraw brushColor={state.color} />`}</span>. We
          randomly change them every 2 seconds. But you could easily use a
          color-picker!
        </p>
        <div>
          Current color:{" "}
          <div
            style={{
              display: "inline-block",
              width: "24px",
              height: "24px",
              backgroundColor: state["color"],
              border: "1px solid #272727"
            }}
          />
        </div>
        <CanvasDraw brushColor={state.color} />
        <h2>Background Image</h2>
        <p>You can also set the `imgSrc` prop to draw on a background-image.</p>
        <p>
          It will automatically resize to fit the canvas and centered vertically
          & horizontally.
        </p>
        <CanvasDraw
          brushColor="rgba(155,12,60,0.3)"
          imgSrc="https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg"
        />

        <h2>Refreshable Background Image</h2>
        <p>This will refresh the background in every two seconds.</p>
        <CanvasDraw
          brushColor="rgba(155,12,60,0.3)"
          imgSrc={state.backgroundImg}
        />
        <h2>Hide UI</h2>
        <p>To hide the UI elements, set the `hideInterface` prop. You can also hide the grid with the `hideGrid` prop.</p>
        <CanvasDraw hideInterface hideGrid />
        <h2>Zoom & Pan</h2>
        <p>
          Set the <span>enablePanAndZoom</span> prop to enable mouse scrolling
          and panning (using Ctrl), pinch zooming, and two-finger panning. If
          you want to ensure that all lines stay within the bounds of the
          canvas, set the <span>clampLinesToDocument</span> property.
        </p>
        <CanvasDraw
          enablePanAndZoom
          clampLinesToDocument
          gridColor="#ccc"
          imgSrc="https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg"
        />
        <h2>Save & Load</h2>
        <p>
          This part got me most excited. Very easy to use saving and loading of
          drawings. It even comes with a customizable loading speed to control
          whether your drawing should load instantly (loadTimeOffset = 0) or
          appear after some time (loadTimeOffset `{'>'}` 0){" "}
          <span>{`<CanvasDraw loadTimeOffset={10} />`}</span>
        </p>
        <p>Try it out! Draw something, hit "Save" and then "Load".</p>
        <div className={style.tools}>
          <button
            onClick={() => {
              localStorage.setItem(
                "savedDrawing",
                saveableCanvas.getSaveData()
              );
            }}
          >
            Save
          </button>
          <button
            onClick={() => {
              saveableCanvas.eraseAll();
            }}
          >
            Erase
          </button>
          <button
            onClick={() => {
              saveableCanvas.undo();
            }}
          >
            Undo
          </button>
          <button
            onClick={() => {
              console.log(saveableCanvas.getDataURL());
              alert("DataURL written to console")
            }}
          >
            GetDataURL
          </button>
          <div>
            <label>Width:</label>
            <input
              type="number"
              value={state.width}
              onChange={e =>
                {state["width"] = parseInt(e.target.value, 10);
                console.log("parseInt(e.target.value, 10): ", parseInt(e.target.value, 10))}
              }
            />
          </div>
          <div>
            <label>Height:</label>
            <input
              type="number"
              value={state.height}
              onChange={e =>
                this.setState({ height: parseInt(e.target.value, 10) })
              }
            />
          </div>
          <div>
            <label>Brush-Radius:</label>
            <input
              type="number"
              value={state.brushRadius}
              onChange={e =>
                this.setState({ brushRadius: parseInt(e.target.value, 10) })
              }
            />
          </div>
          <div>
            <label>Lazy-Radius:</label>
            <input
              type="number"
              value={state.lazyRadius}
              onChange={e =>
                this.setState({ lazyRadius: parseInt(e.target.value, 10) })
              }
            />
          </div>
        </div>
        <CanvasDraw
          ref={canvasDraw => (saveableCanvas = canvasDraw)}
          brushColor={state.color}
          brushRadius={state.brushRadius}
          lazyRadius={state.lazyRadius}
          canvasWidth={state.width}
          canvasHeight={state.height}
        />
        <p>
          The following is a disabled canvas with a hidden grid that we use to
          load & show your saved drawing.
        </p>
        <button
          onClick={() => {
            loadableCanvas.loadSaveData(
              localStorage.getItem("savedDrawing")
            );
          }}
        >
          Load what you saved previously into the following canvas. Either by
          calling `loadSaveData()` on the component's reference or passing it
          the `saveData` prop:
        </button>
        <CanvasDraw
          disabled
          hideGrid
          ref={canvasDraw => (loadableCanvas = canvasDraw)}
          saveData={localStorage.getItem("savedDrawing")}
        />
      </div>


















          </div>
        </div>
        <br />
        <div className={`${style.containers}`}>
        <div className={`${style.buttons}`}>
          <div className={`${style.list}`}>
            {/*<i class="fa fa-list"></i>*/}
            <Link
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    onDebugListToggle(e);
                  }}
                >
                  <i class="fa fa-list"></i>
            </Link>            
          </div>
          <div className={`${style.grid}`}>
            {/*<i class="fa fa-th-large"></i>*/}
            <Link
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    onDebugGridToggle(e);
                  }}
                >
                  <i class="fa fa-th-large"></i>
            </Link>            
          </div>
        </div>
        
        <DropFile />
        
        {check
        ?
        <div className={`${style.wrapperList}`} id="wrapper">
          <div className={`${style.col}`}>
            Column #1
          </div>
          <div className={`${style.col}`}>
            Column #2
          </div>
          <div className={`${style.col}`}>
            Column #3
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #1
          </div>
          <div className={`${style.col}`}>
            Column #2
          </div>
          <div className={`${style.col}`}>
            Column #3
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #1
          </div>
          <div className={`${style.col}`}>
            Column #2
          </div>
          <div className={`${style.col}`}>
            Column #3
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #1
          </div>
          <div className={`${style.col}`}>
            Column #2
          </div>
          <div className={`${style.col}`}>
            Column #3
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
        </div>
        :
        <div className={`${style.wrapper}`} id="wrapper">
          <div className={`${style.col}`}>
            Column #1
          </div>
          <div className={`${style.col}`}>
            Column #2
          </div>
          <div className={`${style.col}`}>
            Column #3
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #1
          </div>
          <div className={`${style.col}`}>
            Column #2
          </div>
          <div className={`${style.col}`}>
            Column #3
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #1
          </div>
          <div className={`${style.col}`}>
            Column #2
          </div>
          <div className={`${style.col}`}>
            Column #3
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #1
          </div>
          <div className={`${style.col}`}>
            Column #2
          </div>
          <div className={`${style.col}`}>
            Column #3
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
        </div>
        }        
      </div>      

      </div>

      {/*
      <br/>
      <div className={`${style.containers}`}>
        <div className={`${style.buttons}`}>
          <div className={`${style.list}`}>
            <Link
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    onDebugListToggle(e);
                  }}
                >
                  <i class="fa fa-list"></i>
            </Link>            
          </div>
          <div className={`${style.grid}`}>
            <Link
                  className={`${style.btn} btn btn-outline-success`}
                  onClick={(e) => {
                    onDebugGridToggle(e);
                  }}
                >
                  <i class="fa fa-th-large"></i>
            </Link>            
          </div>
        </div>
        {check
        ?
        <div className={`${style.wrapperList}`} id="wrapper">
          <div className={`${style.col}`}>
            Column #1
          </div>
          <div className={`${style.col}`}>
            Column #2
          </div>
          <div className={`${style.col}`}>
            Column #3
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
        </div>
        :
        <div className={`${style.wrapper}`} id="wrapper">
          <div className={`${style.col}`}>
            Column #1
          </div>
          <div className={`${style.col}`}>
            Column #2
          </div>
          <div className={`${style.col}`}>
            Column #3
          </div>
          <div className={`${style.col}`}>
            Column #4
          </div>
        </div>
        }        
      </div> 
      */}    
    </section>
	);
}
