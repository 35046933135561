// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wavefooter_linksSection__1A63g {\n  background-color: #ffffff;\n  padding: 0em 0em 0em 0em;\n  margin-bottom: -1px;\n  color: #54616c;\n  transition: 500ms all ease-in-out;\n}\n\n.wavefooter_linksSection__1A63g.wavefooter_nightMode__35D5h {\n  background: var(--nightModeColor);\n  color: #d9d9d9;\n}\n\n/*https://rahuulmiishra.medium.com/cross-fading-conditionally-rendered-react-components-f3deeb05c2cd*/\n.wavefooter_openIt__1_8Hh {\n  transition: 500ms all ease-in-out;\n}\n\n.wavefooter_closeIt__7T6vn {\n  transition: 500ms all ease-in-out;\n}\n", "",{"version":3,"sources":["webpack://src/styles/wavefooter.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,mBAAmB;EACnB,cAAc;EACd,iCAAiC;AACnC;;AAEA;EACE,iCAAiC;EACjC,cAAc;AAChB;;AAEA,qGAAqG;AACrG;EACE,iCAAiC;AACnC;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[".linksSection {\n  background-color: #ffffff;\n  padding: 0em 0em 0em 0em;\n  margin-bottom: -1px;\n  color: #54616c;\n  transition: 500ms all ease-in-out;\n}\n\n.linksSection.nightMode {\n  background: var(--nightModeColor);\n  color: #d9d9d9;\n}\n\n/*https://rahuulmiishra.medium.com/cross-fading-conditionally-rendered-react-components-f3deeb05c2cd*/\n.openIt {\n  transition: 500ms all ease-in-out;\n}\n\n.closeIt {\n  transition: 500ms all ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linksSection": "wavefooter_linksSection__1A63g",
	"nightMode": "wavefooter_nightMode__35D5h",
	"openIt": "wavefooter_openIt__1_8Hh",
	"closeIt": "wavefooter_closeIt__7T6vn"
};
export default ___CSS_LOADER_EXPORT___;
