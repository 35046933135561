import React from 'react';
import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu,
    MenuDivider,
    MenuHeader,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

export default function SubMenuv2(props) {
    const d = props.data;
    
    return (
      <Menu menuButton={<p>{d("header.link8")} <i class="fas fa-chevron-down"></i></p>}
          menuStyles={{
              border: '2px dashed green',
              boxShadow: 'none',
              backgroundColor: '#404040',
              color: 'white',
          }}>

          <MenuItem>New File</MenuItem>
          <MenuItem>Save</MenuItem>
          {/* 
          <MenuItem styles={{
              color: 'blue',
              backgroundColor: '#ee1',
              hover: {
                  color: '#ee1',
                  backgroundColor: '#bf4080'
              },
              active: {
                  backgroundColor: '#333'
              }
          }}>
              I'm special
          </MenuItem>
          */}
          <MenuItem>New File</MenuItem>
          <MenuItem>Save</MenuItem>
          <MenuItem>Close Window</MenuItem>
          <MenuDivider />
          <MenuHeader>Edit</MenuHeader>
          <MenuItem>Cut</MenuItem>
          <MenuItem>Copy</MenuItem>
          <MenuItem>Paste</MenuItem>
          <MenuDivider />
          <MenuItem>Print</MenuItem>
      </Menu>

    );
}