import React, { useState } from "react";
import style from "../styles/resourceProvider.module.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import ResourceProviderItem from "../components/resourceProviders/ResourceProviderItem";

export default function ResourceProvider() {
  const daynight = useSelector((state) => state.data.dayNight);
  const d = useTranslation();

  return (
    <section className={`${daynight ? style.nightMode : ""}`}>
      <div className={`${style.faqsIntroContainer} `}>
        <div className={`container ${style.zIndex1} `}>
          <div className={style.pageTitle}>
            <h1 className={style.creditTitle}>
              {d("credits.creditHeading")}
            </h1>
          </div>
          <div className={style.pageTitleSection}>
            <p data-language-id="creditsDesc">
            {d("credits.creditDescription")}

            </p>
          </div>
        </div>
      </div>
      <div className={`${style.faqsContainer} `}>
        <div className={style.creditItemWrapper}>
          <ResourceProviderItem title={d("credits.credit1.title")} description={d("credits.credit1.description")}></ResourceProviderItem>
          <ResourceProviderItem title={d("credits.credit1.title")} description={d("credits.credit1.description")}></ResourceProviderItem>
          <ResourceProviderItem title={d("credits.credit1.title")} description={d("credits.credit1.description")}></ResourceProviderItem>
          <ResourceProviderItem title={d("credits.credit1.title")} description={d("credits.credit1.description")}></ResourceProviderItem>
          <ResourceProviderItem title={d("credits.credit1.title")} description={d("credits.credit1.description")}></ResourceProviderItem>
        </div>
      </div>
    </section>
  );
}
