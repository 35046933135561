import React from "react";
import style from "../styles/serviceSection.module.css";
import styleSCSSslider from "../styles/tools.module.scss";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";

export default function ServiceSectionSlider() {
  const d = useTranslation();
  const daynight = useSelector((state) => state.data.dayNight);
  return (
    <div
      className={`mt-5 ${style.teamCarousel} ${
        daynight ? style.nightMode : ""
      }`}
    >
      <div
        id="heroSectionSlider"
        className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
        data-ride="carousel"
      >
        <ol className={`carousel-indicators ${style.carousel_indicators}`}>
          <li
            data-target="#heroSectionSlider"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#heroSectionSlider" data-slide-to="1"></li>
          <li data-target="#heroSectionSlider" data-slide-to="2"></li>
          <li data-target="#heroSectionSlider" data-slide-to="3"></li>
          <li data-target="#heroSectionSlider" data-slide-to="4"></li>
        </ol>
        <div className="carousel-inner" role="listbox">
          <div className="carousel-item active">
            <img
              className={style.sliderImage}
              src="/images/logo-and-icons/logo.svg"
              alt=""
            />
            <div className={style.commonText}>
              {d("serviceSlider.slides.slide1.description2")}
            </div>
            <br></br>
            <div className={style.userArea}>
              <img
                className={style.userImage}
                src="/images/styletransfer.jpeg"
                alt=""
              />
              <div>
                <div className={style.userName}>
                  {d("serviceSlider.slides.slide1.title")}
                </div>
                <div className={style.userText}>
                  {d("serviceSlider.slides.slide1.description")}
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              className={style.sliderImage}
              src="/images/logo-and-icons/logo.svg"
              alt=""
            />
            <div className={style.commonText}>
              {d("serviceSlider.slides.slide2.description2")}
            </div>
            <br></br>
            <div className={style.userArea}>
              <img
                className={style.userImage}
                src="/images/superresolution.jpeg"
                alt=""
              />
              <div>
                <div className={style.userName}>
                  {d("serviceSlider.slides.slide2.title")}
                </div>
                <div className={style.userText}>
                  {d("serviceSlider.slides.slide2.description")}
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              className={style.sliderImage}
              src="/images/logo-and-icons/logo.svg"
              alt=""
            />
            <div className={style.commonText}>
              {" "}
              {d("serviceSlider.slides.slide3.description2")}
            </div>
            <br></br>
            <div className={style.userArea}>
              <img
                className={style.userImage}
                src="/images/denoising.jpeg"
                alt=""
              />
              <div>
                <div className={style.userName}>
                  {d("serviceSlider.slides.slide3.title")}
                </div>
                <div className={style.userText}>
                  {d("serviceSlider.slides.slide3.description")}
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              className={style.sliderImage}
              src="/images/logo-and-icons/logo.svg"
              alt=""
            />
            <div className={style.commonText}>
              {" "}
              {d("serviceSlider.slides.slide4.description2")}
            </div>
            <br></br>
            <div className={style.userArea}>
              <img
                className={style.userImage}
                src="/images/slowmo.jpeg"
                alt=""
              />
              <div>
                <div className={style.userName}>
                  {d("serviceSlider.slides.slide4.title")}
                </div>
                <div className={style.userText}>
                  {d("serviceSlider.slides.slide4.description")}
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              className={style.sliderImage}
              src="/images/logo-and-icons/logo.svg"
              alt=""
            />
            <div className={style.commonText}>
              {" "}
              {d("serviceSlider.slides.slide5.description2")}
            </div>
            <br></br>
            <div className={style.userArea}>
              <img
                className={style.userImage}
                src="/images/dehazing.jpeg"
                alt=""
              />
              <div>
                <div className={style.userName}>
                  {d("serviceSlider.slides.slide5.title")}
                </div>
                <div className={style.userText}>
                  {d("serviceSlider.slides.slide5.description")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          href="#heroSectionSlider"
          className="carousel-control-prev d-none"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon"></span>
        </a>

        <a
          href="#heroSectionSlider"
          className="carousel-control-next d-none"
          data-slide="next"
        >
          <span className="carousel-control-next-icon"></span>
        </a>
      </div>
      
      {/*
      <div className={`d-flex align-items-center justify-content-center`}>
        <figure className={`mt-3 ${styleSCSSslider.iconCards}`}>
          <div className={`${styleSCSSslider.iconCards__content}`}>
            <div className={`d-flex align-items-center justify-content-center ${styleSCSSslider.iconCards__item}`}>
                <img
                  className={style.sliderImage}
                  src="/images/logo-and-icons/logo.svg"
                  alt=""
                />
                <div className={style.commonText}>
                  {d("serviceSlider.slides.slide1.description2")}
                </div>
                <br></br>
                <div className={style.userArea}>
                  <img
                    className={style.userImage}
                    src="/images/styletransfer.jpeg"
                    alt=""
                  />
                  <div>
                    <div className={style.userName}>
                      {d("serviceSlider.slides.slide1.title")}
                    </div>
                    <div className={style.userText}>
                      {d("serviceSlider.slides.slide1.description")}
                    </div>
                  </div>
                </div>
            </div>
            <div className={`d-flex align-items-center justify-content-center ${styleSCSSslider.iconCards__item}`}>
              <img
                className={style.sliderImage}
                src="/images/logo-and-icons/logo.svg"
                alt=""
              />
              <div className={style.commonText}>
                {d("serviceSlider.slides.slide1.description2")}
              </div>
              <br></br>
              <div className={style.userArea}>
                <img
                  className={style.userImage}
                  src="/images/styletransfer.jpeg"
                  alt=""
                />
                <div>
                  <div className={style.userName}>
                    {d("serviceSlider.slides.slide1.title")}
                  </div>
                  <div className={style.userText}>
                    {d("serviceSlider.slides.slide1.description")}
                  </div>
                </div>
              </div>
            </div>
            <div className={`d-flex align-items-center justify-content-center ${styleSCSSslider.iconCards__item}`}>
              <img
                className={style.sliderImage}
                src="/images/logo-and-icons/logo.svg"
                alt=""
              />
              <div className={style.commonText}>
                {d("serviceSlider.slides.slide1.description2")}
              </div>
              <br></br>
              <div className={style.userArea}>
                <img
                  className={style.userImage}
                  src="/images/styletransfer.jpeg"
                  alt=""
                />
                <div>
                  <div className={style.userName}>
                    {d("serviceSlider.slides.slide1.title")}
                  </div>
                  <div className={style.userText}>
                    {d("serviceSlider.slides.slide1.description")}
                  </div>
                </div>
              </div>            
            </div>
          </div>
        </figure>
      </div>
      */}
    </div>
  );
}
