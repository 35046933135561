import React from "react";
import style from "../styles/blogSection.module.css";

export default function BlogSection(props) {
  const d = props.data;
  return (
    <section className={style.blogSection}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <a href="/" className={`${style.subHeading} subHeading`}>
              {d("blog.title")} <i className="fas fa-chevron-circle-right"></i>
            </a>
            <div className={style.blogLinks}>
              <a className={style.blogLink} href="/">
                <div className={style.blogLinkText}>{d("blog.blogText.text1")}</div>
                <strong>{d("blog.blogText.text2")}</strong>
              </a>
              <a className={style.blogLink} href="/">
                <div className={style.blogLinkText}>{d("blog.blogText.text3")}</div>
                <strong>{d("blog.blogText.text4")}</strong>
              </a>
              <a className={style.blogLink} href="/">
                <div className={style.blogLinkText}>{d("blog.blogText.text5")}</div>
                <strong>{d("blog.blogText.text6")}</strong>
              </a>
            </div>
          </div>
          <div className="col-lg-5 mt-5 mt-lg-0">
            <h2 className={`${style.subHeading} subHeading`}>{d("blog.title2")}</h2>
            <div className={style.mainText}>{d("blog.description")}</div>
            <div className={style.searchArea}>
              <input type="email" name="" id="" placeholder="Enter your email" />
              <button className={`btn btn-primary ${style.btn_primary}`}>Subscribe</button>
            </div>
            <div className={`smText ${style.smText}`}>
              {d("blog.description2.text1")}
              <a href="/"> {d("blog.description2.text2")} </a> {d("blog.description2.text3")}
              <a href="/"> {d("blog.description2.text4")} </a> {d("blog.description2.text5")}.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
