import React, { useState } from "react";
import style from "../../styles/windowsScreenStyle/faqSection.module.css";
import { Accordion, Card, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function FAQsection() {
  const daynight = useSelector((state) => state.data.dayNight);
  return (
    <section class={`${style.fqSection} ${daynight ? style.nightMode : ""}`}>
      <div class="container">
        <h2 class="subHeading mb-3">Frequently asked questions</h2>
        <Accordion>
        <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                How does dioptify for Windows/Mac/Linux work?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className={style.accordion_content}>
                <p>
                  dioptify for Windows/Mac/Linux is a software that you can
                  drag images into and it removes the background from these
                  images. You can drag images or even whole folders of images
                  from anywhere on your computer and define yourself where to
                  save the background-free images in the settings. You can also
                  pick a background color and size which will be applied to all
                  images.
                </p>
                <p>
                  Images are processed through the dioptify
                  <a href="#">API</a>, which requires an active Internet
                  connection and an API key (to be found in your
                  <a href="#">account settings</a>
                  ).
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                How much does dioptify for Windows/Mac/Linux cost?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body className={style.accordion_content}>
                {" "}
                <p>
                  Downloading dioptify for Windows/Mac/Linux is free! To
                  process images you need credits, which can be used for all
                  dioptify products and integrations. You can find the pricing
                  for credits <a href="#">here</a>.
                </p>
                <p>
                  As a <a href="#">dioptify user</a> you get 1 free credit for
                  signing up, and 50 free API calls for preview images each
                  month. Since dioptify for Windows/Mac/Linux utilizes the API
                  that means you can process 50 preview images (up to 0.25
                  megapixels, e.g. 625x400) with it for free each month.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="2">
                What are the system requirements?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body className={style.accordion_content}>
                <p class="mb-0">
                  <strong>Windows</strong>: Windows 7/8/10 (64-bit
                </p>
                <p class="mb-0">
                  <strong>Mac</strong>: macOS 10.10 or newer (64-bit)
                </p>
                <p class="mb-0">
                  <strong>Linux</strong>: Ubuntu/Debian (64-bit)
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="3">
                How to install dioptify for Windows/Mac/Linux?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body className={style.accordion_content}>
                <p>
                  You can find the installation instructions for your operating
                  system <a href="#">here</a>.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                How does dioptify for Windows/Mac/Linux work?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className={style.accordion_content}>
                <p>
                  dioptify for Windows/Mac/Linux is a software that you can
                  drag images into and it removes the background from these
                  images. You can drag images or even whole folders of images
                  from anywhere on your computer and define yourself where to
                  save the background-free images in the settings. You can also
                  pick a background color and size which will be applied to all
                  images.
                </p>
                <p>
                  Images are processed through the dioptify
                  <a href="#">API</a>, which requires an active Internet
                  connection and an API key (to be found in your
                  <a href="#">account settings</a>
                  ).
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                How much does dioptify for Windows/Mac/Linux cost?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body className={style.accordion_content}>
                {" "}
                <p>
                  Downloading dioptify for Windows/Mac/Linux is free! To
                  process images you need credits, which can be used for all
                  dioptify products and integrations. You can find the pricing
                  for credits <a href="#">here</a>.
                </p>
                <p>
                  As a <a href="#">dioptify user</a> you get 1 free credit for
                  signing up, and 50 free API calls for preview images each
                  month. Since dioptify for Windows/Mac/Linux utilizes the API
                  that means you can process 50 preview images (up to 0.25
                  megapixels, e.g. 625x400) with it for free each month.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="2">
                What are the system requirements?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body className={style.accordion_content}>
                <p class="mb-0">
                  <strong>Windows</strong>: Windows 7/8/10 (64-bit
                </p>
                <p class="mb-0">
                  <strong>Mac</strong>: macOS 10.10 or newer (64-bit)
                </p>
                <p class="mb-0">
                  <strong>Linux</strong>: Ubuntu/Debian (64-bit)
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="3">
                How to install dioptify for Windows/Mac/Linux?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body className={style.accordion_content}>
                <p>
                  You can find the installation instructions for your operating
                  system <a href="#">here</a>.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                How does dioptify for Windows/Mac/Linux work?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className={style.accordion_content}>
                <p>
                  dioptify for Windows/Mac/Linux is a software that you can
                  drag images into and it removes the background from these
                  images. You can drag images or even whole folders of images
                  from anywhere on your computer and define yourself where to
                  save the background-free images in the settings. You can also
                  pick a background color and size which will be applied to all
                  images.
                </p>
                <p>
                  Images are processed through the dioptify
                  <a href="#">API</a>, which requires an active Internet
                  connection and an API key (to be found in your
                  <a href="#">account settings</a>
                  ).
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                How much does dioptify for Windows/Mac/Linux cost?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body className={style.accordion_content}>
                {" "}
                <p>
                  Downloading dioptify for Windows/Mac/Linux is free! To
                  process images you need credits, which can be used for all
                  dioptify products and integrations. You can find the pricing
                  for credits <a href="#">here</a>.
                </p>
                <p>
                  As a <a href="#">dioptify user</a> you get 1 free credit for
                  signing up, and 50 free API calls for preview images each
                  month. Since dioptify for Windows/Mac/Linux utilizes the API
                  that means you can process 50 preview images (up to 0.25
                  megapixels, e.g. 625x400) with it for free each month.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="2">
                What are the system requirements?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body className={style.accordion_content}>
                <p class="mb-0">
                  <strong>Windows</strong>: Windows 7/8/10 (64-bit
                </p>
                <p class="mb-0">
                  <strong>Mac</strong>: macOS 10.10 or newer (64-bit)
                </p>
                <p class="mb-0">
                  <strong>Linux</strong>: Ubuntu/Debian (64-bit)
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className={style.article_title}>
              <Accordion.Toggle as={Button} variant="link" eventKey="3">
                How to install dioptify for Windows/Mac/Linux?
                <i class="fas fa-plus more-less glyphicon glyphicon-plus"></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body className={style.accordion_content}>
                <p>
                  You can find the installation instructions for your operating
                  system <a href="#">here</a>.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </section>
  );
}
