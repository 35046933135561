import React, { useState } from "react";
import style from "../styles/cookies.module.css";
import CookiesTable from "../components/CookiesTable";
import data from "../data/cookiesTableData.json";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";
import CookiesPolicyModal from "../components/CookiesPolicyModal";

export default function AboutUs() {
  const [show, setShow] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  const handleShow = () => setShow(true);
  return (
    <>
      {cookiesModal ? <CookiesModal /> : ""}
      <div className={`${style.cookiesSection} ${daynight ? style.nightMode : ""}`}>
        <div class="col-lg-12 mx-auto">
          <div className={style.heroSection}>
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className={style.blob}>
                <path fill="#A7F0BA" d="M53.8,-40.7C65.3,-28.4,67.2,-6.3,62,13.2C56.7,32.7,44.4,49.5,25.6,61.9C6.9,74.2,-18.2,82,-34.9,73.5C-51.6,65.1,-59.9,40.3,-65.3,15.3C-70.6,-9.7,-73.1,-34.9,-61.7,-47.2C-50.3,-59.4,-25.2,-58.6,-2,-57C21.1,-55.4,42.3,-53,53.8,-40.7Z" transform="translate(100 100)" />
              </svg>

              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className={style.blob1}>
                <path fill="#A7F0BA" d="M47.7,-51.5C58.6,-36.9,61.9,-18.4,62.7,0.7C63.4,19.9,61.5,39.7,50.6,56.2C39.7,72.7,19.9,85.9,2.6,83.2C-14.6,80.6,-29.2,62.2,-45.4,45.7C-61.6,29.2,-79.4,14.6,-81.2,-1.9C-83.1,-18.3,-69,-36.6,-52.8,-51.3C-36.6,-65.9,-18.3,-76.8,0.1,-76.9C18.4,-76.9,36.9,-66.1,47.7,-51.5Z" transform="translate(100 100)" />
              </svg>

            <div class={style.halfCircle}></div>
            <div class={style.circle}></div>
            <img class={style.toolsDotsImg} src="/images/black-dots-img.svg" alt="" />
            <img class={style.toolsDotsImgTwo} src="/images/black-dots-img.svg" alt="" />
            <div class={style.triangle}></div>
            <img class={style.downArrowsImg} src="/images/down-arrows-img.svg" alt="" />
            {/*<h1 className="mainHeading text-white">Cookie Policy</h1>*/}
            <h1 class="mainHeading mb-1">AboutUs</h1>
            <h4 className="commonText">Version: 2021-03-30</h4>
          </div>
          <div className="mx-2">
            <div className={style.card}>
              <p>
                To provide a great product experience and for certain functions, we use so-called cookies. Cookies are
                small text files that are stored on your device. You can find an overview of all cookies we use, their
                purpose, retention period and usage class below.
              </p>
              <p>
                Any information that we collect through cookies will be used in accordance with our Privacy Policy. If you
                do not accept the use of these cookies, you can disable them by following the instructions in the section
                below (Cookie Settings).
              </p>
              <h3 className="commonText">What are cookies?</h3>
              <p>
                Cookies are small text files that uniquely identify your browser or device. The cookie file is stored on
                your browser. When you return to that website (or visit websites that use the same cookies) these websites
                recognize the cookies and your browsing device.
              </p>
              <p>
                Cookies do many different jobs, like letting you navigate between pages efficiently, remembering your
                preferences, and generally improving your experience. Cookies can tell us, for example, whether you have
                visited our websites before.
              </p>
              <h3 className="commonText">Are there different types of cookies?</h3>
              <p>
                There are different types of cookies, including ‘first party cookies’ (which are served directly by us to
                your computer or device) and ‘third-party cookies’ (which are served by a third party on our behalf).
              </p>
              <p>
                Cookies can remain on your device for different periods of time. Some cookies are ‘session cookies’,
                meaning that they exist only while your browser is open and are deleted automatically once you close your
                browser. Other cookies are ‘permanent cookies’, meaning that they survive after your browser is closed.
                They can be used to recognise your computer when you open your browser and browse the Internet again.
              </p>
              <p>Finally, cookies are used for different purposes.</p>
              <ul>
                <li>
                  ‘Essential cookies’ are needed to provide you with the website and to use some of its features, such as
                  access to secure areas. Without these cookies, we would not be able to provide you with the website and
                  services that you have asked for.
                </li>
                <li>
                  ‘Functionality cookies’ record information about choices you have made and allow us to provide relevant
                  content and tailor our websites for you.
                </li>
                <li>
                  ‘Analytics cookies’ help us to measure traffic and usage data and to analyze how our websites are used
                  in order to provide you with a better user experience and maintain, operate and improve our services.
                </li>
              </ul>
              <p>We have set out the cookies that we used on our websites below.</p>
              <h3 className="commonText">First-party cookies</h3>
              <CookiesTable data={data[0]} />

              <h3 className="commonText">Third-party cookies</h3>
              <CookiesTable data={data[1]} />
              <CookiesTable data={data[2]} />
              <CookiesTable data={data[3]} />
              <CookiesTable data={data[4]} />
              <h3 className="commonText">Cookie Settings</h3>
              <p>
                Some of these cookies are essential and we cannot provide our service to you without them, but there are
                others that can be turned off. Please note that turning off certain cookies will disable some of the
                features available to you through our websites. We cannot guarantee that your experience on our websites
                will be as good as it could otherwise be.
              </p>
              <p>To change your cookie preferences, please click the button below.</p>
              <button className={`btn btn-primary ${style.btn_primary} mb-3`} onClick={handleShow}>
                Manage Cookies
              </button>
              <p>
                You can also change your preferences by changing the settings in your browser. For more information on how
                to manage the most popular browsers, please see below:
              </p>
              <ul>
                <li>
                  <a href="#">Google Chrome</a>
                </li>
                <li>
                  <a href="#">Internet Explorer</a>
                </li>
                <li>
                  <a href="#">Firefox</a>
                </li>
                <li>
                  <a href="#">Safari</a>
                </li>
              </ul>
              <p>
                If you want to change your settings at any time (for example, if you accept all cookies but later decide
                you do not want a certain type of cookie), you’ll need to use your browser settings to remove any third
                party cookies dropped on your previous visit.
              </p>
              <p>
                In addition to managing cookies in your browser settings, you can also opt out of certain third party
                cookies. For example, we use Hotjar and Google Analytics to help us understand how users engage with our
                websites, compile reports and improve our services. The reports disclose website trends without
                identifying individual users. You can opt out of these cookies without affecting how you visit our
                websites. For more information on Google Analytics, see <a href="#">here</a>, and to opt out from being
                tracked by Google Analytics, see <a href="#">here</a>. To learn more about Hotjar, see here, and to opt
                out from being tracked by Hotjar, see
                <a href="#">here</a>.
              </p>
            </div>
          </div>
        </div>
      </div>

      <CookiesPolicyModal show={show} setShow={setShow} />
    </>
  );
}
