import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteImage } from "../redux/action/image";
import { useTranslation } from "react-multi-lang";
import style from "../styles/ImageEditor.module.css";
import { saveAs } from "file-saver";
import { Tabs, Tab } from "react-bootstrap";
import {
  checkIfInferencedImageAvailable,
  cutLongFilename,
  decodeServiceName,
} from "../utils/helpers";

function ImageContainer(props) {
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [imageResolution, setImageResolution] = useState([1, 1]);
  const daynight = useSelector((state) => state.data.dayNight);
  const [key, setKey] = useState('original');
  const d = useTranslation();
  const dispatch = useDispatch();
  const item = props.data;

  const i = props.index;

  var xxx = -1;
  var yyy = -1;
  function getMeta(url) {
    const img = new Image();
    img.src = url;
    let k = [];
    // var xxx;
    // var yyy;
    // img.onload = function() {   console.log("-------------------> the item:1 " + this.width, this.height);  }
    img.onload = function () {
      k.push(this.width, this.height);
      xxx = this.height;
      yyy = this.width;
    };

    var width = img.width;
    var height = img.height;
    // console.log("-------------------> the item afterafterafter: " + width, height);
    // console.log("-------------------> the item awdawdaw: " + xxx, yyy);
    // console.log("-------------------> the item afterafterafter: " + k);
    if (width != 0) {
      setImageResolution([width, height])
    } else {
      setImageResolution([1, 1])
    }
    setImageLoaded(true)

  }

  const onImageLoad = (url) => {
    getMeta(url)
  }

  useEffect(() => {
    // setLoaderVisible(true);
    setLoaderVisible(false); // moved this loader anim to any upload page
    setTimeout(() => {
      setLoaderVisible(false);
    }, 3000);
  }, [item]);

  const saveFile = () => {
    var filename = item.image.substring(item.image.lastIndexOf("/") + 1);
    saveAs(item.image, filename);
  };

  return (
    <div>
      {loaderVisible ? (
        <div className="text-center">
          <img src="/images/loader.gif" alt="" />
        </div>
      ) : (
        <div className={`${daynight ? `nightModeTab ${style.ImageEditorContainer}` : ` ${style.ImageEditorContainer}`}`}>
          <a
            href="/"
            className={style.closeBtn}
            onClick={(e) => {
              e.preventDefault();
              dispatch(deleteImage(item.id));
            }}
          >
            {/*<img src="/images/icons8-multiply-192.png" alt="" />*/}
            <i
              class="fa fa-times fa-lg"
              aria-hidden="true"
              style={{ color: "var(--dioptifyGreen)" }}
            ></i>
          </a>
          <Tabs
            defaultActiveKey={
              checkIfInferencedImageAvailable(item.service)
                ? "inferenced"
                : "original"
            }
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(key) => setKey(key)}
          >
            <Tab eventKey={"original"} title="Original">
              <ImageTab
                i={i}
                key={i}
                item={item}
                image={item.originalImage}
                saveFile={saveFile}
                service={item.service}
                paid={item.paid}
                onImageLoad={onImageLoad}
                imageLoaded={imageLoaded}
                imageResolution={imageResolution}
              ></ImageTab>
            </Tab>
            {/* <Tab eventKey={"inferenced"} title="Inferenced" disabled={!checkIfInferencedImageAvailable(item.service)}> */}
            <Tab eventKey={"inferenced"} title="Inferenced">
              <ImageTab
                i={i}
                onImageLoad={onImageLoad}
                imageLoaded={imageLoaded}
                item={item}
                image={item.image}
                key={i}
                saveFile={saveFile}
                imageResolution={imageResolution}
                service={item.service}
                paid={item.paid}
              ></ImageTab>
            </Tab>
          </Tabs>

          {/*
          <div className={style.footer}>
            <a href="/">
              Happy Easter!{" "}
              <span> Design a unique gift for your family and friends –</span>
              <span>Try now</span>
              <i className="fas fa-external-link-alt"></i>
            </a>
          </div>
          */}
        </div>
      )}
    </div>
  );
}
const ImageTab = (props) => {

  console.log(props.image)
  const d = useTranslation();
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-9">
          <div>
            <div
              className={`${style.itemsLinks} itemsLinks ${style.nav} nav`}
              id="myTab"
              role="tablist"
            >
              {/*
                  <a
                    className={`${style.itemLink} active`}
                    id={`ta-${i + 2}`}
                    data-toggle="tab"
                    href={`#ta${i + 2}`}
                    role="tab"
                    aria-controls={`ta${i + 2}`}
                    aria-selected="false"
                  >
                    {d("imageEditor.text2")}
                  </a>
                  */}

              {/*
                  <a
                    className={style.itemLink}
                    id={`tab-${i + 1}`}
                    data-toggle="tab"
                    href={`#tab${i + 1}`}
                    role="tab"
                    aria-controls={`tab${i + 1}`}
                    aria-selected="true"
                  >
                    {d("imageEditor.text1")}
                  </a>
                  */}
            </div>

            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade text-center show active"
                id={`tab${props?.i + 1}`}
                role="tabpanel"
                aria-labelledby={`tab-${props?.i + 1}`}
              >
                <div>
                  {props.imageLoaded ? (
                    <img
                      src={props?.image}
                      alt="Loaded Image"
                    />
                  ) : (
                    <div>Loading...</div>
                  )}
                  <img
                    src={props?.image}
                    alt=""
                    style={{
                      width: parseInt(props?.item?.width / 5),
                      height: parseInt(props?.item?.height / 5),
                      display: 'none'
                    }}
                    onLoad={() => { props?.onImageLoad(props?.image) }}
                  />

                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-4 mt-md-0">
          <div className={style.action_btn}>
            <button className="btn btn-secondary" onClick={props.saveFile}>
              Download <i className="fas fa-cloud-download-alt"></i>{" "}
            </button>
            <div className={style.imageDetailsContainer}>
              <span>
                <a style={{ fontWeight: 800, color: "var(--dioptifyGreen)" }}>
                  {d("imageEditor.text7")}
                </a>:{" "}
                <a style={{ textOverflow: "ellipsis" }}>
                  <span> {cutLongFilename(
                    props?.image?.substring(
                      props?.image?.lastIndexOf("/") + 1
                    ),
                    20,
                    d
                  )}</span>
                </a>
              </span>
              <span>
                <a style={{ fontWeight: 800, color: "var(--dioptifyGreen)" }}>
                  {d("imageEditor.text3")}
                </a>{" "}
                <span>{props?.imageResolution[0]} ×{" "}
                  {props?.imageResolution[1]}</span>

              </span>
              <span>
                <a style={{ fontWeight: 800, color: "var(--dioptifyGreen)" }}>
                  {d("imageEditor.text8")}
                </a>
                :{" "}
                <span>{d(decodeServiceName(props?.service))}</span>

              </span>
              <span>
                <a style={{ fontWeight: 800, color: "var(--dioptifyGreen)" }}>
                  {d("imageEditor.text9")}
                </a>
                :{" "}
                {props?.paid ? (
                  <i
                    className={`fa fa-check ${style.checkMarkSuccess}`}
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className={`fa fa-times ${style.checkMarkFailure}`}
                    aria-hidden="true"
                  ></i>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageContainer;
