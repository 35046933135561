import React from "react";
import style from "../styles/subHeroSection.module.css";
import { useSelector } from "react-redux";

export default function SubHeroSection(props) {
  const d = props.data;
  const daynight = useSelector((state) => state.data.dayNight);
  return (
    <section className={`heroSection ${style.subHeroSection} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        {/* <img className={style.arrowImage} src="/images/ArrowDown.png" alt="" /> */}
        <div className="row">
          <div className="col-lg-6 mt-3 mt-lg-5 pr-3 pr-md-5">
            <h1 className={`mainHeading ${style.mainHeading}`}>{d("subHeroSection.title")}</h1>
            <div className={`mainText ${style.mainText}`}>{d("subHeroSection.descriptionUpdate")}</div>
          </div>
          <div className="col-lg-6">
            <div className={style.heroContentSide}>
              <div className={style.uploadImageCard}>
                <div className={style.uploadImageCardBody}>
                  <a className={`btn btn-primary ${style.btn_primary}`} href="/sr">
                    {d("heroSection.gohere")} <i class="fas fa-arrow-right"></i>
                  </a>
                  
                  {/*
                  <div className={`${style.mainText} mainText text-center`}>{d("subHeroSection.drop")}</div>
                  */}    
                </div>
              </div>

              {/*
              <div className={`${style.otherImages} mt-4`}>
                <div className="mt-4">
                  <img className={style.othersImage} src="/images/sm-img1.jpg" alt="" />
                  <img className={style.othersImage} src="/images/sm-img1.jpg" alt="" />
                  <img className={style.othersImage} src="/images/sm-img1.jpg" alt="" />
                  <img className={style.othersImage} src="/images/sm-img1.jpg" alt="" />
                </div>
              </div>
              */}
              <div className={`${style.smText} smText`}>
                {d("subHeroSection.uploadText.text1")}
                <a href="/termsofservice"> {d("subHeroSection.uploadText.text2")}. </a>
                {/*
                {d("subHeroSection.uploadText.text3")}
                <a href="/"> {d("subHeroSection.uploadText.text4")} </a> {d("subHeroSection.uploadText.text5")}
                <a href="/"> {d("subHeroSection.uploadText.text6")} </a> {d("subHeroSection.uploadText.text7")}.
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
