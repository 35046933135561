import React, { useState } from "react";
import style from "../styles/cookies.module.css";
import CookiesTable from "../components/CookiesTable";
import data from "../data/cookiesTableData.json";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";
import CookiesPolicyModal from "../components/CookiesPolicyModal";
import { useTranslation } from "react-multi-lang";
import HeroSectionHome from "../components/addOnsComponent/HeroSectionHome";
import DetailsSection from "../components/addOnsComponent/DetailsSection";
import SiteSection from "../components/addOnsComponent/SiteSection";
import HeroSection from "../components/addOnsComponent/HeroSection";



export default function AddOnsFull() {
  const [show, setShow] = useState(false);
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  const data = useTranslation();

  const handleShow = () => setShow(true);
  return (
    <>
      {/* <SiteSection data={data} /> */}
      {/* <DetailsSection /> */}
      <HeroSection data={data}/>
      <DetailsSection />
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
