import React from "react";
import { useTranslation } from "react-multi-lang";
import style from "../../styles/resourceProvider.module.css";


export default function ResourceProviderItem(props) {
  const d = useTranslation();

  return (
    <div className={`${style.hiddenDiv} ${style.delayedDiv} ${style.zIndex1}`}>
      <div className={style.creditItem}>
        <div className={style.creditsItemIcon}>
          <div className={style.containerHCenter}>
            <div class="container-hcenter-vcenter">
              <img
                class="credits-item-icon-img"
                src="/images/credits_icon_jquery.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className={style.creditItemUrl}>
          <a
            href="https://jquery.com/"
            target="_blank"
            class="link-blue txt-overflow-ellipsis txt-nowrap"
          >
            https://jquery.com
          </a>
        </div>
        <div
          className={`${style.creditItemComment} ${style.txtContentgraydark}`}
        >
          <span>
           {props.description}
          </span>
          
        </div>
      </div>

      <div class="clear"></div>
    </div>
  );
}
