import React from "react";
import style from "../../styles/exploreSection.module.css";
import { useSelector } from "react-redux";

export default function HeroSection(props) {
  const daynight = useSelector((state) => state.data.dayNight);
  const d = props.data;

  return (
    <section class={`${style.toolsSection} ${daynight ? style.nightMode : ""}`}>
      <div class="container">
        <div class="col-lg-12 mx-auto">
          <div class={style.heroSectionContents}>
            <h1 class="mainHeading mb-1">{d("addons.title")}</h1>
          </div>
        </div>
      </div>
    </section>
  );
}
