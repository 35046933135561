import React from "react";
import style from "../../styles/tools.module.css";
import { useSelector } from "react-redux";

export default function DetailsSection() {
  const daynight = useSelector((state) => state.data.dayNight);
  return (
    <section
      class={`${style.detailsSection} ${daynight ? style.nightMode : ""}`}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7 mx-auto">
            <h2 class="subHeading">More Apps & Integrations</h2>
            <div class={`row mt-4 ${style.customRow}`}>
              <div class="col-lg-6">
                <a class="commonLink" href="#">
                  Mobile App<i class="fas fa-chevron-right"></i>
                </a>
                <div class={style.oddText}>Available for Android</div>
              </div>
              <div class="col-lg-6">
                <a class="commonLink" href="#">
                  Zapier Integration<i class="fas fa-chevron-right"></i>
                </a>
                <div class={style.oddText}>Connect 2,000+ apps with ease</div>
              </div>
              <div class="col-lg-6">
                <a class="commonLink" href="#">
                  Commond Link Tool<i class="fas fa-chevron-right"></i>
                </a>
                <div class={style.oddText}>CLI-based background removal</div>
              </div>
              <div class="col-lg-6">
                <a class="commonLink" href="#">
                  Interomat Integration<i class="fas fa-chevron-right"></i>
                </a>
                <div class={style.oddText}>
                  Flexibly connect hundreds of services
                </div>
              </div>
            </div>
            <h2 class="subHeading mt-4">Plugins by the dioptify community</h2>
            <div class="commonText">
              Check out these community-made plugins to use dioptify in <br />
              your favorite design tool or ecommerce platform.
            </div>
            <div class={`row mt-4 ${style.customRow}`}>
              <div class="col-lg-6">
                <a class="commonLink" href="#">
                  Plugin for Figma<i class="fas fa-chevron-right"></i>
                </a>
                <div class={style.oddText}>by @aaroniker</div>
              </div>
              <div class="col-lg-6">
                <a class="commonLink" href="#">
                  Plugin for Sketch<i class="fas fa-chevron-right"></i>
                </a>
                <div class={style.oddText}>by @mathieudutour</div>
              </div>
              <div class="col-lg-6">
                <a class="commonLink" href="#">
                  Plugin for WooCommerce<i class="fas fa-chevron-right"></i>
                </a>
                <div class={style.oddText}>by @avs2738</div>
              </div>
              <div class="col-lg-6">
                <a class="commonLink" href="#">
                  Plugin for Visual Studio Code
                  <i class="fas fa-chevron-right"></i>
                </a>
                <div class={style.oddText}>by @antonholmberg</div>
              </div>
              <div class="col-lg-6">
                <a class="commonLink" href="#">
                  Plugin for Drupal<i class="fas fa-chevron-right"></i>
                </a>
                <div class={style.oddText}>by david.froehlich</div>
              </div>
              <div class="col-lg-6">
                <a class="commonLink" href="#">
                  Plugin for Shopware 5<i class="fas fa-chevron-right"></i>
                </a>
                <div class={style.oddText}>by @karlbauerde</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
