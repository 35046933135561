import React from "react";
import HeroSection from "../components/templateScreenComponent/HeroSection";
import DetailsSection from "../components/templateScreenComponent/DetailsSection";
import SiteSection from "../components/templateScreenComponent/SiteSection";
import CookiesModal from "../components/CookiesModal";
import { useSelector } from "react-redux";

export default function PageTemplate() {
  const cookiesModal = useSelector((state) => state.data.cookiesModal);
  return (
    <>
      <SiteSection />
      <HeroSection />
      <DetailsSection />
      {cookiesModal ? <CookiesModal /> : ""}
    </>
  );
}
