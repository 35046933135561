import React, { useState, useEffect } from 'react';
import axiosInstance, { baseMLURL } from '../../axios';
import { Link } from 'react-router-dom';
import { Alert } from "react-bootstrap";

import style from "../../styles/uploadSection.module.css";
import ImageEditor from "../ImageEditor";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { setImage } from "../../redux/action/image";
import { SERVICES } from './Services';
import axios from 'axios';
import Loader from "../../components/loaderComponent/Loader";
import HowItWorks from "../servicesComponents/uploadServiceSectionTasksComponent/HowItWorks";
import Dropzone, { useDropzone } from "react-dropzone";
import MultiStepProgressBar from "../MultiStepProgressBar/MultiStepProgressBar";

export default function UploadServiceSectionTasks(props) {
	const daynight = useSelector((state) => state.data.dayNight);

	let resSuccess = false;
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
    const [files, setFiles] = useState([]);
	const [rejectedFiles, setRejectedFiles] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);
    const [count, setCount] = useState(-1);
    const [errorMessage, showErrorMessage] = useState();
    const [showProgressBar, setShowProgressBar] = useState(true);
	const [checked, setChecked] = useState(true);
	let   [chipData, updateChipData] = useState(0);
	const [freePLan, setPlan] = useState(false);
	const d = props.data;
	const isImagePick = useSelector((state) => state.data.image.length);
	const service = props.service;

	const dispatch = useDispatch();

	useEffect(() => {
		const fetchUser = async () => {
		  if(localStorage.getItem('user') !== null) {
			getChipAmount();
		  } else {
			setPlan(true);
		  }
		};
		fetchUser();	
	}, []);

  const resetUploadingProcess =()=>{
	setShowProgressBar(true)
    setCount(0)
	setRejectedFiles([])
	setUploadedFiles([])
  }

  const onImageChange = async (files) => {

	resetUploadingProcess();
	
    if (Array.isArray(files)) {
      if(files.length > 10){
        showErrorMessage('error.uploadImageMaxLimitExceded')
        setShow(true);
        setShowProgressBar(false)
        return;
      }

      setFiles(files);

      for (const [index, file] of files.entries()) {
        try {
          const res = await uploadImage(file,index,files);
          setCount((count) => count + 1);
        } catch (error) {
          console.error(error);
        }
      }
    }

  };

	const uploadImage = async(file,index,files) => {
		if (file) {
			let img = file;
			let slug = uuidv4();
			
			let user = 'anon';
			if(localStorage.getItem('user') !== null) {
				user = localStorage.getItem('user');
			} else {
				if(localStorage.getItem('temp_user') === null) {
					user = uuidv4();
					localStorage.setItem('temp_user', user);
				} else {
					user = localStorage.getItem('temp_user');
				}
			}

		//	event.preventDefault();
			let formData = new FormData();
			formData.append('title', uuidv4());
			formData.append('slug', slug);
			formData.append('service', service);
			formData.append('owner', user);
			formData.append('excerpt', uuidv4());
			formData.append('content', uuidv4());
			formData.append('new_model', checked);
			formData.append('is_editor_upload', false);
			formData.append('image', img);
			
            setLoading(true); // to show the loader svg

			const getStatus = async(taskID) => {
				await axiosInstance.get(`createimage/${taskID}/`).then((res) => {
				  const taskStatus = res.data.task_status;
				  setShow(false);
				  if (taskStatus === 'SUCCESS' ) getProcessedImage(index,files);
				  if (taskStatus === 'SUCCESS' || taskStatus === 'FAILURE') return taskStatus;
				  setTimeout(function() {
					getStatus(res.data.task_id);
				  }, 1000); // this recalls the function recursive to see if the tasks has ended
		  
				})
				.catch((err) => {
				  console.log("Unable to get at the moment: " + err);
				  // TODO: add here some toast or other message to show an error 
				  setLoading(false);
				  console.log("file")

				  setShow(true);
               //  setShowProgressBar(false)
				  setTimeout(() => {
					setShow(false);
				  }, 4000);
				});
			}
		
			const getProcessedImage = async(index,files) => {

				axiosInstance.get('post/' + slug).then((res) => {

					resSuccess = true;

					//setLoading(false); // to disable the loader svg
					setShow(false);
					if(files.length > 1) {
						setUploadedFiles(uploadedFiles => [...uploadedFiles,index] );
					}else {
						setLoading(false);
					}

				    //setUploadedFiles(uploadedFiles.concat(index))

					// TODO: change to more mature logic here
					// tests due to some services overwrite original files
					if(service !== "rb" && service !== "hdr" && service !== "cl" && service !== "ds" && service !== "rf") {				
						if(!(res.data.paid)) {
							dispatch(setImage({ image: res.data.preview_image, service:res?.data?.service, originalImage:res?.data?.preview_image, paid:false, id: uuidv4() }));	
						} else {
							dispatch(setImage({ image: res.data.image, service:res?.data?.service, originalImage:res?.data?.image, paid:true, id: uuidv4() }));
						}
					} else {

						if(!(res.data.paid)) {
							// TODO: check if this correct for all services
							// dispatch(setImage({ image: res.data.preview_image, id: uuidv4() }));	
							dispatch(setImage({ image: res?.data?.original_image, originalImage:res?.data?.image, service:res?.data?.service, paid:true, id: uuidv4() }));
						} else {
							dispatch(setImage({ image: res?.data?.original_image, originalImage:res?.data?.image, service:res?.data?.service, paid:false, id: uuidv4() }));
						}
					}
					
					// to be dynamic when all the credits are gone it needs to update the cost
					getChipAmount();
				})
				.catch((err) => {
					setLoading(false); // to disable the loader svg
					// alert("Unable to upload your image at the moment: " + err);
					setShow(true);
                   // setShowProgressBar(false)
				   setRejectedFiles(rejectedFiles => [...rejectedFiles,index] );
					setTimeout(() => {
					  setShow(false);
					}, 4000);
				});
			}

			await axiosInstance.post(`createimage/`, formData).then((res) => {
				let taskStatus = getStatus(res.data.task_id); // TESTs
				setShow(false);

				// this way don't work because the taskStatus is pending and will not get set to success
				// console.log("dddddddddddddddddddddd taskStatus: ", taskStatus);
				// if (taskStatus === 'SUCCESS') getProcessedImage();

				resSuccess = true;
				

			})
			.catch((err) => {
				resSuccess = false;
                setLoading(false); // to disable the loader svg
				setShow(true);
				setRejectedFiles(rejectedFiles => [...rejectedFiles,index] );
				setTimeout(() => {
				  setShow(false);
				}, 4000);
			});
		}
	};


	const getChipAmount = async(event) => {    
		let formData2 = new FormData();
		formData2.append('email', uuidv4());
		formData2.append('username', uuidv4());

		console.log("get chippp ammount")
	
		await axiosInstance.get(`user/creditscore/`, formData2).then((res) => {
		  updateChipData(res.data.credit_score);
		  // updateChipData is not working in time
		  chipData = res.data.credit_score;

		  if(chipData > 0) {
			  setPlan(false);
		  } else {
			  setPlan(true);
		  }
		})
		.catch((err) => {
		  console.log("Unable to upload your file at the moment: " + err);
		});
	};	
	
	const handleChange = () => {
		setChecked(!checked);
	};

    const resetUploadingBar = () =>{
		setLoading(false)
    }

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: onImageChange,
		accept: {
		  "image/jpeg": [".jpg", ".jpeg"],
		  "image/png": [".png"],
		},
		multiple: true
	  });

	return (
		<>
			{show ? (
			<Alert
				variant="danger" // warning
				style={{ position: "fixed", left: "50%", transform: "translateX(-50%)", top: "36px", zIndex: "1040" }}
			>
				{errorMessage ? d(errorMessage) : d("error.errorUpload")}
			</Alert>
			) : (
			""
			)}
			<section 
				className={`${style.heroSection} ${daynight ? style.nightMode : ""}`}
			>
				<div className="col-lg-12">
					{isImagePick !== 0 ? (
						<>
						<div className={`${style.btn_container}`}>
							<div className={`dropdown ${style.dropdown}`}>
							<Link
								to="#"
								className={`${style.buttonAdventureWithoutDarkmode} btn btn-outline-secondary`}
								onClick={(e) => {}}
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								>
								{d("heroSection.uploadText.text11")} <i class="fa fa-angle-down" aria-hidden="true"></i>
							</Link>
								<div className={`dropdown-menu ${style.dropdownMenu}`} aria-labelledby="dropdownMenuButton">
									<Link
										to="/rbg"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-camera-retro fa-lg"></i>
										<>&nbsp;</>{d("serviceRB")}
									</Link>
									<br/>
									<Link
										to="/sr"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-balance-scale fa-lg"></i>
										<>&nbsp;</>{d("serviceSR")}
									</Link>
									<br/>
									<Link
										to="/db"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-eye fa-lg"></i>
										<>&nbsp;</>{d("serviceDB")}
									</Link>
									<br />
									<Link
										to="/hdr"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-database fa-lg"></i>
										<>&nbsp;</>{d("serviceHDR")}
									</Link>
									<br/>
									<Link
										to="/sns"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-gift fa-lg"></i>
										<>&nbsp;</>{d("serviceNS")}
									</Link>
									<br/>
									<Link
										to="/cl"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-paint-brush fa-lg"></i>
										<>&nbsp;</>{d("serviceCL")}
									</Link>
									<br/>
									<Link
										to="/ds"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-bullseye fa-lg"></i>
										<>&nbsp;</>{d("serviceDS")}
									</Link>
									<br/>
									<Link
										to="/rf"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-cubes fa-lg"></i>
										<>&nbsp;</>{d("serviceRF")}
									</Link>
									<br/>
									<Link
										to="/fb"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-user fa-lg"></i>
										<>&nbsp;</>{d("serviceFB")}
									</Link>
									<br/>
									<Link
										to="/ii"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-bath fa-lg"></i>
										<>&nbsp;</>{d("serviceII")}
									</Link>
									<br/>
									<Link
										to="/sd"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-id-badge fa-lg"></i>
										<>&nbsp;</>{d("serviceSD")}
									</Link>
									<br/>
									<Link
										to="/pe"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-barcode fa-lg"></i>
										<>&nbsp;</>{d("servicePE")}
									</Link>									
								</div>
							</div>						
						</div>
						
						<div className="">
							<div className={style.heroContentSide}>
							<div
                          className={`${style.uploadImageCard } ${
                            isDragActive ? style.active : ""
                          }`}
                        >
                          <section>
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              <label
                                for="uploadImage"
                                className={`${style.uploadImageCardBody} w-100`}
                              >
                                <img
                                  src="/images/logo-and-icons/new3small.svg"
                                  alt=""
                                />
                                <label
                                  for="uploadImage"
                                  className={`btn btn-secondary ${style.btn_primary}`}
                                  href="/"
                                >
                                  <i className="fas fa-upload"></i>{" "}
                                  {d("heroSection.upload")}
                                </label>
                                <div
                                  className={`${style.mainText} mainText`}
                                >
									                                {d("heroSection.uploadText.text13")}

								</div>
                              </label>
                              {loading ? (
                                <div className="text-center">
                                  <Loader></Loader>
                                  <br />
                                  <br />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </section>
                          { files?.length > 1 && showProgressBar ? <MultiStepProgressBar files={files} count={count} rejectedFiles={rejectedFiles} uploadedFiles={uploadedFiles} resetUploadingBar = {resetUploadingBar}  /> : ""}
                        </div>
							
							<b className={`${style.expText}`}>Service: </b>
							{(service == SERVICES.NS || service == SERVICES.SNS) ?  (
								<p className={`${style.expText}`}>Style Transfer</p>
							) : ""}
							{service == SERVICES.SR ?  (
								<p className={`${style.expText}`}>Super Resolution</p>
							) : ""}
							{service == SERVICES.DH ?  (
								<p className={`${style.expText}`}>Dehazing</p>
							) : ""}
							{service == SERVICES.DB ?  (
								<p className={`${style.expText}`}>Deblurring</p>
							) : ""}
							{service == SERVICES.FI ?  (
								<p className={`${style.expText}`}>Frame Interpolation</p>
							) : ""}
							{service == SERVICES.THREEDP ?  (
								<p className={`${style.expText}`}>3D Photos</p>
							) : ""}
							{service == SERVICES.RB ?  (
								<p className={`${style.expText}`}>Background Remover</p>
							) : ""}
							{service == SERVICES.HDR ?  (
								<p className={`${style.expText}`}>High Dynamic Range</p>
							) : ""}
							{service == SERVICES.II ?  (
								<p className={`${style.expText}`}>Image Inpainting</p>
							) : ""}
							{service == SERVICES.CL ?  (
								<p className={`${style.expText}`}>Colouring</p>
							) : ""}
							{service == SERVICES.DS ?  (
								<p className={`${style.expText}`}>Deshaking</p>
							) : ""}
							{service == SERVICES.RF ?  (
								<p className={`${style.expText}`}>Refocus</p>
							) : ""}
							{service == SERVICES.FB ?  (
								<p className={`${style.expText}`}>Face Blurring</p>
							) : ""}
							<b className={`${style.expText}`}>{d("heroSection.uploadText.text10")}: </b>
							{freePLan ?  (
								<p className={`${style.expText}`}>{d("heroSection.uploadText.text8")}</p>
							) : 
								<p className={`${style.expText}`}>{d("heroSection.uploadText.text9")}</p>
							}

							{(service == SERVICES.SR) || (service == SERVICES.RB) ?  (
								<>
									<b className={`${style.expText}`}>{d("heroSection.uploadText.text12")}: </b>
									<input
										type="checkbox"
										name=""
										id=""
										className={`${style.checkBoxChecked} mr-2`}
										checked={checked}
										onChange={handleChange}
									/>
								</>
							) : ""}
							
							<div className={`${style.smText} smText`}>
								{d("heroSection.uploadText.text1")}
								<a href="/termsofservice"> {d("heroSection.uploadText.text2")} </a>
							</div>

							</div>
						</div>
						</>
					) : (
						<div className="container">
    
                        {(service == SERVICES.NS || service == SERVICES.SNS) ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageNS")}
                            </h1>
                        ) : ""}                                
                        {service == SERVICES.SR ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageSR")}
                            </h1>
                        ) : ""}                                
                        {service == SERVICES.DH ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageDH")}
                            </h1>
                        ) : ""}                                
                        {service == SERVICES.DB ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageDB")}
                            </h1>
                        ) : ""}                                
                        {service == SERVICES.FI ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageFI")}
                            </h1>
                        ) : ""}                                
                        {service == SERVICES.THREEDP ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImage3DP")}
                            </h1>
                        ) : ""}                                
                        {service == SERVICES.RB ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageRB")}
                            </h1>
                        ) : ""}                                
                        {service == SERVICES.HDR ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageHDR")}
                            </h1>
                        ) : ""}                                
                        {service == SERVICES.II ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageII")}
                            </h1>
                        ) : ""}
						{service == SERVICES.CL ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageCL")}
                            </h1>
                        ) : ""}
						{service == SERVICES.DS ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageDS")} (Experimental)
                            </h1>
                        ) : ""}
						{service == SERVICES.RF ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageRF")}
                            </h1>
                        ) : ""}
						{service == SERVICES.FB ?  (
                            <h1 className={`${style.mainHeading} mainHeading text-center`}>
                                {d("uploadImageFB")}
                            </h1>
                        ) : ""}
    
						<div className={`${style.btn_container}`}>
							<div className={`dropdown ${style.dropdown}`}>
							{/*
								https://stackoverflow.com/questions/70499543/create-react-app-creates-this-iframe-that-prevents-me-from-clicking-or-editing
								https://github.com/facebook/create-react-app/issues/11880
								https://github.com/remix-run/react-router/issues/8489
							*/}							
							<Link
								to="#"
								className={`${style.buttonAdventureWithoutDarkmode} btn btn-outline-secondary`}
								onClick={(e) => {}}
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								>
								{d("heroSection.uploadText.text11")} <i class="fa fa-angle-down" aria-hidden="true"></i>
							</Link>
								<div className={`dropdown-menu ${style.dropdownMenu}`} aria-labelledby="dropdownMenuButton">
									<Link
										to="/rbg"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-camera-retro fa-lg"></i>
										<>&nbsp;</>{d("serviceRB")}
									</Link>
									<br/>
									<Link
										to="/sr"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-balance-scale fa-lg"></i>
										<>&nbsp;</>{d("serviceSR")}
									</Link>
									<br/>
									<Link
										to="/db"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-eye fa-lg"></i>
										<>&nbsp;</>{d("serviceDB")}
									</Link>
									<br />
									<Link
										to="/hdr"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-database fa-lg"></i>
										<>&nbsp;</>{d("serviceHDR")}
									</Link>
									<br/>
									<Link
										to="/sns"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-gift fa-lg"></i>
										<>&nbsp;</>{d("serviceNS")}
									</Link>
									<br/>
									<Link
										to="/cl"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-paint-brush fa-lg"></i>
										<>&nbsp;</>{d("serviceCL")}
									</Link>
									<br/>
									<Link
										to="/ds"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-bullseye fa-lg"></i>
										<>&nbsp;</>{d("serviceDS")}
									</Link>
									<br/>
									<Link
										to="/rf"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-cubes fa-lg"></i>
										<>&nbsp;</>{d("serviceRF")}
									</Link>
									<br/>
									<Link
										to="/fb"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-user fa-lg"></i>
										<>&nbsp;</>{d("serviceFB")}
									</Link>
									<br/>
									<Link
										to="/ii"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-bath fa-lg"></i>
										<>&nbsp;</>{d("serviceII")}
									</Link>
									<br/>
									<Link
										to="/sd"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-id-badge fa-lg"></i>
										<>&nbsp;</>{d("serviceSD")}
									</Link>
									<br/>
									<Link
										to="/pe"
										className={`${style.btn} btn dropdown-item`} 
									>
										<>&nbsp;</>
										<i className="fas fa-barcode fa-lg"></i>
										<>&nbsp;</>{d("servicePE")}
									</Link>									
								</div>
							</div>						
						</div>

							<div className="row">
								<div className={`col-xl-6`}>
									<div
										className={style.heroContentSide}
										style={{ width: "100%", maxWidth: "420px", marginTop: "3em" }}
									>
									<div
                          className={`${style.uploadImageCard} ${
                            isDragActive ? style?.active : ""
                          }`}
                        >
                          <section>
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              <label
                                for="uploadImage"
                                className={`${style.uploadImageCardBody} w-100`}
                              >
                                <img
                                  src="/images/logo-and-icons/new3small.svg"
                                  alt=""
                                />
                                <label
                                  for="uploadImage"
                                  className={`btn btn-secondary ${style.btn_primary}`}
                                  href="/"
                                >
                                  <i className="fas fa-upload"></i>{" "}
                                  {d("heroSection.upload")}
                                </label>
                                <div
                                  className={`${style.mainText} mainText`}
                                >
									                                {d("heroSection.uploadText.text13")}

								</div>
                              </label>
                              {loading ? (
                                <div className="text-center">
                                  <Loader></Loader>
                                  <br />
                                  <br />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </section>
                          { files?.length > 1 && showProgressBar ? <MultiStepProgressBar files={files} count={count} rejectedFiles={rejectedFiles} uploadedFiles = {uploadedFiles} resetUploadingBar = {resetUploadingBar}  /> : ""}
                        </div>

										<b className={`${style.expText}`}>Service: </b>
										{(service == SERVICES.NS || service == SERVICES.SNS) ?  (
											<p className={`${style.expText}`}>Style Transfer</p>
										) : ""}
										{service == SERVICES.SR ?  (
											<p className={`${style.expText}`}>Super Resolution</p>
										) : ""}
										{service == SERVICES.DH ?  (
											<p className={`${style.expText}`}>Dehazing</p>
										) : ""}
										{service == SERVICES.DB ?  (
											<p className={`${style.expText}`}>Deblurring</p>
										) : ""}
										{service == SERVICES.FI ?  (
											<p className={`${style.expText}`}>Frame Interpolation</p>
										) : ""}
										{service == SERVICES.THREEDP ?  (
											<p className={`${style.expText}`}>3D Photos</p>
										) : ""}
										{service == SERVICES.RB ?  (
											<p className={`${style.expText}`}>Background Remover</p>
										) : ""}
										{service == SERVICES.HDR ?  (
											<p className={`${style.expText}`}>High Dynamic Range</p>
										) : ""}
										{service == SERVICES.II ?  (
											<p className={`${style.expText}`}>Image Inpainting</p>
										) : ""}
										{service == SERVICES.CL ?  (
											<p className={`${style.expText}`}>Colouring</p>
										) : ""}
										{service == SERVICES.DS ?  (
											<p className={`${style.expText}`}>Deshaking</p>
										) : ""}
										{service == SERVICES.RF ?  (
											<p className={`${style.expText}`}>Refocus</p>
										) : ""}
										{service == SERVICES.FB ?  (
											<p className={`${style.expText}`}>Face Blurring</p>
										) : ""}
										<b className={`${style.expText}`}>{d("heroSection.uploadText.text10")}: </b>
										{freePLan ?  (
											<p className={`${style.expText}`}>{d("heroSection.uploadText.text8")}</p>
										) : 
											<p className={`${style.expText}`}>{d("heroSection.uploadText.text9")}</p>
										}

										{(service == SERVICES.SR) || (service == SERVICES.RB) ?  (
											<>
												<b className={`${style.expText}`}>{d("heroSection.uploadText.text12")}: </b>
												<input
													type="checkbox"
													name=""
													id=""
													className={`${style.checkBoxChecked} mr-2`}
													checked={checked}
													onChange={handleChange}
												/>
											</>
										) : ""}

										<div className={`${style.smText} smText`}>
											{d("heroSection.uploadText.text1")}
											<a href="/termsofservice"> {d("heroSection.uploadText.text2")} </a>
										</div>
									</div>

								</div>

								<HowItWorks data={d} service={service}/>
							</div>
						</div>
					)}
				</div>
			</section>
			{isImagePick !== 0 ? <ImageEditor /> : ""}
		</>
	);
}