import React from "react";
import style from "../../../styles/windowsScreenStyle/midSection.module.css";
import { useSelector } from "react-redux";

export default function MidSection() {
  const daynight = useSelector((state) => state.data.dayNight);
  return (
    <section class={`${style.midSection} ${daynight ? style.nightMode : ""}`}>
      <div class="container">
        <div class={`row mt-5 ${style.customRow}`}>
          <div class="col-lg-6 mt-3 mt-lg-0">
            <h2 class="subHeading mb-2">Choose Background and Size</h2>
            <div class="commonText">
              Define once, apply to all files: Whether you need transparent or
              colored backgrounds, small or large images – you are in control.
              Select your preferences and they will apply to all files
              automatically.
            </div>
          </div>
          <div class="col-lg-6">
            <div class={style.midSectionLeftSide}>
              <div class={style.squareBox}></div>
              <img
                class={style.blackDotsImage}
                src="/images/black-dots-img.svg"
                alt=""
              />
              <img
                class={`img-fluid ${style.mainImg}`}
                src="https://static.remove.bg/remove-bg-web/a72f919da581145bc8a52ac0c5d21f5c4741f367/assets/desktop_bg-dd957fa8af79f3ea0c3e05484f47e63695afa2ca467dde93883856535e89ce99.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
