import React, { useState, useEffect } from "react";
import style from "../../../styles/multiSelectionUploadSection.module.css";
import Loader from "../../../components/loaderComponent/Loader";

import { useSelector } from "react-redux";

export default function MultiSelectionUploadSection(props) {
  const d = props.data;
  const services = props.services;
  const daynight = useSelector((state) => state.data.dayNight);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    console.log("selected services: ", services);
  });

  return (
    <section className={`heroSection ${style.subHeroSection} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mt-3 mt-lg-5 pr-3 pr-md-5">
            <h1 className={`mainHeading ${style.mainHeading}`}>{d("subHeroSectionGeneric.title")}</h1>
            <div className={`mainText ${style.mainText}`}>{d("subHeroSectionGeneric.description")}</div>
          </div>
          <div className="col-lg-6">
            <div className={style.heroContentSide}>
              <div className={style.uploadImageCard}>
                <div className={style.uploadImageCardBody}>
								  <label
                    for="uploadImage"
                    className={`btn btn-secondary ${style.btn_primary}`}
                    href="/"
									>
				  					<i className="fas fa-cloud-upload-alt"></i> {d("heroSection.upload")}
									</label>
					
          				<input
                    className="d-none"
                    type="file"
                    id="uploadImage"
                    accept="image/*"
                  />
								<div className={`${style.mainText} mainText text-center`}>{d("subHeroSection.drop")}</div>
								{loading ?  (
									<div className="text-center">
										<Loader></Loader>
									</div>
								) : ""}                                
								</div>
              </div>
              <div className={`${style.smText} smText`}>
                {d("subHeroSectionGeneric.uploadText.text1")}
                <a href="/termsofservice"> {d("subHeroSectionGeneric.uploadText.text2")}. </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
