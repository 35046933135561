import React from "react";
import style from "../../styles/templateTools.module.css";
import { useSelector } from "react-redux";

export default function HeroSection() {
  const daynight = useSelector((state) => state.data.dayNight);
  return (
    <section class={`${style.toolsSection} ${daynight ? style.nightMode : ""}`}>
      <div class={style.halfCircle}></div>
      <div class={style.circle}></div>
      <img class={style.toolsDotsImg} src="/images/black-dots-img.svg" alt="" />
      <img class={style.toolsDotsImgTwo} src="/images/black-dots-img.svg" alt="" />
      <div class={style.triangle}></div>
      <img class={style.downArrowsImg} src="/images/down-arrows-img.svg" alt="" />
      <div class="container">
        <div class="col-lg-12 mx-auto">
          <div class={style.heroSectionContents}>
            <h1 class="mainHeading mb-1">Tools & API</h1>
            <div class="commonText">Make dioptify part of your workflow</div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class={style.toolsCard}>
                <a class={style.imgLink} href="#">
                  <img
                    class="img-fluid"
                    src="/images/cysharpUI.jpg"
                  />
                </a>
                <h2 class={style.subHeading}>
                  dioptify for <br />
                  Windows / Mac / Linux
                </h2>
                <a href="#" class={`btn btn-primary ${style.btn}`}>
                  Learn more
                </a>
                <a href="#" class={`btn btn-outline-primary ${style.btn} ${style.outline}`}>
                  Download
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class={style.toolsCard}>
                <a class={style.imgLink} href="#">
                  <img
                    class="img-fluid"
                    src="/images/psUI.jpg"
                  />
                </a>
                <h2 class={style.subHeading}>
                  dioptify for <br />
                  Photoshop
                </h2>
                <a href="#" class={`btn btn-primary ${style.btn}`}>
                  Learn more
                </a>
                <a href="#" class={`btn btn-outline-primary ${style.btn} ${style.outline}`}>
                  Download
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class={style.toolsCard}>
                <a class={style.imgLink} href="#">
                  <a href="/api">
                    <img
                      class="img-fluid"
                      src="/images/rest-api-resized.png"
                    />
                  </a>
                </a>
                <h2 class={style.subHeading}>
                  API <br />
                  Integration
                </h2>
                <a href="#" class={`btn btn-primary ${style.btn}`}>
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
