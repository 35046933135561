import React, { useState } from "react";
import style from "../styles/cookies.module.css";
import { useSelector } from "react-redux";
import CookiesModal from "../components/CookiesModal";
import FAQsection from "../components/helpAndFaqsComponent/FAQsection";
import Cta from "../components/helpAndFaqsComponent/Cta";
import CookiesPolicyModal from "../components/CookiesPolicyModal";
import { useTranslation } from "react-multi-lang";

export default function HelpAndFAQs() {
  const [show, setShow] = useState(false);
  const data = useTranslation();
  const daynight = useSelector((state) => state.data.dayNight);
  const cookiesModal = useSelector((state) => state.data.cookiesModal);

  const handleShow = () => setShow(true);
  return (
    <>
      {cookiesModal ? <CookiesModal /> : ""}
      <div className={`${style.cookiesSection} ${daynight ? style.nightMode : ""}`}>
        <div class="col-lg-12 mx-auto">
          <div className={style.heroSection}>
            <h1 class="mainHeading mb-1">{data("faq.title")}</h1>
            <h4 className="commonText">Version: 2021-03-30</h4>
          </div>
        </div>
        <Cta split data={data}/>
        <FAQsection />
      </div>

      <CookiesPolicyModal show={show} setShow={setShow} />
    </>
  );
}
