import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

// Pages
import Home from "./pages/Home";
import SignIn from "./pages/Login";
import Single from './components/posts/single';
import SingleVideo from './components/posts/singlevideo';
import MultiPost from './components/posts/singlemultipost';
import Admin from './pages/Admin.js';
import Edit from './components/admin/edit';
import Create from './components/admin/create';
import Delete from './components/admin/delete';
import PostOverview from './pages/PostOverview';
import Services from './pages/Services';
import MultiSelection from "./pages/MultiSelection";
import Site404 from "./pages/Site404";
import Debug from "./pages/Debug";
import Debugv2 from "./pages/Debugv2";
import Debugv3 from "./pages/Debugv3";
import Debugv4 from "./pages/Debugv4";
import User from "./pages/User";
import PageTemplate from "./pages/PageTemplate";
import Explore from "./pages/Explore";
import ExploreFull from "./pages/ExploreFull";

import windowsMacLinux from "./pages/windowsMacLinux";
import Photoshop from "./pages/Photoshop";
import Office365 from "./pages/Office365";
import Tools from "./pages/Tools";
import Timeline from "./pages/Timeline";
import Cookies from "./pages/Cookies";
import AboutUs from "./pages/AboutUs";
import AboutUsFull from "./pages/AboutUsFull";
import AddOns from "./pages/AddOns";
import AddOnsFull from "./pages/AddOnsFull";
import APIDocumentation from "./pages/APIDocumentation";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import Enterprise from "./pages/Enterprise";
import HelpAndFAQs from "./pages/HelpAndFAQs";
import Imprint from "./pages/Imprint";
import Pricing from "./pages/Pricing";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import TermsOfService from "./pages/TermsOfService";

import Auth from "./pages/Auth";
import AuthLayout from "./pages/layout/AuthLayout";
import FrontLayout from "./pages/layout/FrontLayout";

// Services
import RemoveBackground from "./components/services/RemoveBackground/RemoveBackground";
import Colorization from "./components/services/Colorization/Colorization";
import Deshaking from "./components/services/Deshaking/Deshaking";
import Refocus from "./components/services/Refocus/Refocus";
import FaceBlurring from "./components/services/FaceBlurring/FaceBlurring";
import StableDiffusion from "./components/services/StableDiffusion/StableDiffusion";
import PaintByExample from "./components/services/PaintByExample/PaintByExample";
import RemoveBackgroundVideo from "./components/services/RemoveBackgroundVideo/RemoveBackgroundVideo";
import NeuralStyle from "./components/services/NeuralStyle/NeuralStyle";
import SophisticatedNeuralStyle from "./components/services/NeuralStyle/SophisticatedNeuralStyle";
import NeuralStyleVideo from "./components/services/NeuralStyleVideo/NeuralStyleVideo";
import SuperResolution from "./components/services/SuperResolution/SuperResolution";
import Dehazing from "./components/services/Dehazing/Dehazing";
import Deblurring from "./components/services/Deblurring/Deblurring";
import HighDynamicRange from "./components/services/HighDynamicRange/HighDynamicRange";
import HighDynamicRangeVideo from "./components/services/HighDynamicRangeVideo/HighDynamicRangeVideo";
import ImageInpainting from "./components/services/ImageInpainting/ImageInpainting";
import ImageInpaintingNew from "./components/services/ImageInpaintingNew/ImageInpaintingNew";
import ImageInpaintingVideo from "./components/services/ImageInpaintingVideo/ImageInpaintingVideo";
import FrameInterpolation from "./components/services/FrameInterpolation/FrameInterpolation";
import I3DPhoto from "./components/services/I3DPhoto/I3DPhoto";
import MultiService from "./components/services/MultiService/MultiService";

// Services Info
import FIDetail from "./pages/ServicesDetailInformation/FIDetail";
import HDRDetail from "./pages/ServicesDetailInformation/HDRDetail";
import IIDetail from "./pages/ServicesDetailInformation/IIDetail";
import NSTDetail from "./pages/ServicesDetailInformation/NSTDetail";
import SRDetail from "./pages/ServicesDetailInformation/SRDetail";
import RBDetail from "./pages/ServicesDetailInformation/RBDetail";
import DBDetail from "./pages/ServicesDetailInformation/DBDetail";
import CLDetail from "./pages/ServicesDetailInformation/CLDetail";
import DSDetail from "./pages/ServicesDetailInformation/DSDetail";
import RFDetail from "./pages/ServicesDetailInformation/RFDetail";
import FBDetail from "./pages/ServicesDetailInformation/FBDetail";
import SDDetail from "./pages/ServicesDetailInformation/SDDetail";
import PEDetail from "./pages/ServicesDetailInformation/PEDetail";

// Utils
import ScrollToTop from "./utils/ScrollToTop";
import NewFAQs from "./pages/NewFAQs";
import Editor from "./pages/imageEditor/components/App/Editor";
import AssemblyPoint from "./pages/imageEditor/components/AssemblyPoint";
import ResourceProvider from "./pages/ResourceProvider";


export default class Routes extends Component {
  render() {
    return (
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={[
              "/",
              "/home",
              "/debug",
              "/desktop",
              "/photoshop",
              "/office365",
              "/tools-api",
              "/history",
              "/explore",
              "/cookies",
              "/pricing",
              "/api",
              "/addons",
              "/faqs",
              "/newfaqs",
              "/contact",
              "/enterprise",
              "/career",
              "/aboutus",
              "/privacy",
              "/termsofservice",
              "/termsandconditions",
              "/imprint",
              "/multiselection",
              "/ns",
              "/nsv",
              "/sns",
              "/sr",
              "/dh",
              "/db",
              "/3dp",
              "/hdr",
              "/hdrv",
              "/ii",
              "/vi",
              "/fi",
              "/sd",
              "/pe",
              "/ms",
              "/auth",
              "/admin",
              "/posts",
              "/services",
              "/authentication",
              "/admin/edit/:id",
              "/admin/delete/:id",
              "/post/:slug",
              "/editor",
              "*",
            ]}
          >
            <FrontLayout>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />                
                <Route exact path="/desktop" component={windowsMacLinux} />
                <Route exact path="/photoshop" component={Photoshop} />
                <Route exact path="/office365" component={Office365} />
                <Route exact path="/tools-api" component={Tools} />
                <Route exact path="/history" component={Timeline} />
                <Route exact path="/explore" component={ExploreFull} />
                <Route exact path="/cookies" component={Cookies} />
                <Route exact path="/pricing" component={Pricing} />
                {/* <Route exact path="/api" component={APIDocumentation} /> */}
                {/* <Route exact path="/addons" component={AddOnsFull} /> */}
                <Route exact path="/faqs" component={NewFAQs} />
                {/* <Route exact path="/credits" component={ResourceProvider} /> */}

                <Route exact path="/privacy" component={PrivacyPolicy} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/enterprise" component={Enterprise} />
                <Route exact path="/termsandconditions" component={TermsAndConditions} />
                <Route exact path="/imprint" component={Imprint} />
                <Route exact path="/career" component={Career} />
                <Route exact path="/aboutus" component={AboutUsFull} />
                <Route exact path="/termsofservice" component={TermsOfService} />
                <Route exact path="/multiselection" component={MultiSelection} />
                <Route exact path="/services" component={Services} />
                <Route exact path="/editor" component={AssemblyPoint} />

                {/*
                <Route exact path="/debug" component={Debug} />
                <Route exact path="/debugv2" component={Debugv3} />
                <Route exact path="/debugv3" component={Debugv4} />
                */}
                <Route exact path="/user" component={User} />

                <Route exact path="/ns" component={NeuralStyle} />
                <Route exact path="/sns" component={SophisticatedNeuralStyle} />
                <Route exact path="/nsv" component={NeuralStyleVideo} />
                <Route exact path="/sr" component={SuperResolution} />
                <Route exact path="/dh" component={Dehazing} />
                <Route exact path="/db" component={Deblurring} />
                <Route exact path="/3dp" component={I3DPhoto} />
                <Route exact path="/hdr" component={HighDynamicRange} />
                <Route exact path="/hdrv" component={HighDynamicRangeVideo} />
                <Route exact path="/ii" component={ImageInpaintingNew} />
                <Route exact path="/vi" component={ImageInpaintingVideo} />
                <Route exact path="/fi" component={FrameInterpolation} />
                <Route exact path="/rbg" component={RemoveBackground} />
                <Route exact path="/rbgv" component={RemoveBackgroundVideo} />
                <Route exact path="/cl" component={Colorization} />
                <Route exact path="/ds" component={Deshaking} />
                <Route exact path="/rf" component={Refocus} />
                <Route exact path="/fb" component={FaceBlurring} />
                <Route exact path="/sd" component={StableDiffusion} />
                <Route exact path="/pe" component={PaintByExample} />
                <Route exact path="/ms" component={MultiService} />

                <Route exact path="/services/sr/info" component={SRDetail} />
                <Route exact path="/services/rb/info" component={RBDetail} />
                <Route exact path="/services/db/info" component={DBDetail} />
                <Route exact path="/services/sns/info" component={NSTDetail} />
                <Route exact path="/services/fi/info" component={FIDetail} />
                <Route exact path="/services/hdr/info" component={HDRDetail} />
                <Route exact path="/services/ii/info" component={IIDetail} />
                <Route exact path="/services/cl/info" component={CLDetail} />
                <Route exact path="/services/ds/info" component={DSDetail} />
                <Route exact path="/services/rf/info" component={RFDetail} />
                <Route exact path="/services/fb/info" component={FBDetail} />
                <Route exact path="/services/sd/info" component={SDDetail} />
                <Route exact path="/services/pe/info" component={PEDetail} />

                <Route exact path="/auth" component={SignIn} />
                <Route exact path="/admin" component={Admin} />
                <Route exact path="/posts" component={PostOverview} />
                <Route exact path="/authentication" component={Auth} />

                <Route exact path="/admin/create" component={Create} />
                <Route exact path="/admin/edit/:id" component={Edit} />
                <Route exact path="/admin/delete/:id" component={Delete} />
                <Route exact path="/post/:slug" component={Single} />
                <Route exact path="/videopost/:slug" component={SingleVideo} />
                <Route exact path="/multiservicepost/:slug" component={MultiPost} />

                <Route path="*" component={Site404}  />
              </Switch>
            </FrontLayout>
          </Route>
        </Switch>
      </ScrollToTop>
    );
  }
}
