// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stunningQuality_stunningSection__2CdG- {\n  border-bottom: 1px solid #edeff0;\n  padding: 4em 0 4em 0;\n  transition: 500ms all ease-in-out;\n}\n.stunningQuality_nightMode__3fUt2 {\n  background-color: var(--nightModeColor);\n}\n.stunningQuality_nightMode__3fUt2 h2 {\n  color: #d9d9d9;\n}\n.stunningQuality_nightMode__3fUt2 a {\n  color: #d9d9d9 !important;\n}\n", "",{"version":3,"sources":["webpack://src/styles/stunningQuality.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,oBAAoB;EACpB,iCAAiC;AACnC;AACA;EACE,uCAAuC;AACzC;AACA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".stunningSection {\n  border-bottom: 1px solid #edeff0;\n  padding: 4em 0 4em 0;\n  transition: 500ms all ease-in-out;\n}\n.nightMode {\n  background-color: var(--nightModeColor);\n}\n.nightMode h2 {\n  color: #d9d9d9;\n}\n.nightMode a {\n  color: #d9d9d9 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stunningSection": "stunningQuality_stunningSection__2CdG-",
	"nightMode": "stunningQuality_nightMode__3fUt2"
};
export default ___CSS_LOADER_EXPORT___;
