import React, { Component, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { render } from "react-dom";

import CanvasDraw from "react-canvas-draw";
import style from "../styles/debug.module.scss";

export default function Debugv3(props) {
  const daynight = useSelector((state) => state.data.dayNight);

  const initStateObj = Object.freeze({
    color: "#ffc600",
    width: 400,
    height: 400,
    brushRadius: 10,
    lazyRadius: 1, // 12
    backgroundImg: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
    imgs: [
      "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
      "https://i.imgur.com/a0CGGVC.jpg"
    ],
    customImg: null,
    reduxState : "reduxState"
	});

  const initStateImageObj = Object.freeze({
    customImg: null,
	});

  const initDims = Object.freeze({
    width: null,
    height: null,
	});

  const [stateObj, setState] = useState(initStateObj);
  const [stateObjImage, setStateImage] = useState(initStateImageObj);
  const [stateDims, setStateDims] = useState(initDims);

  let uploadedImage = React.createRef();
  let imageUploader = React.createRef();

  const handleImageUpload = e => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      const readerAdvanced = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
      }

      const readFile = (file) => {
        readerAdvanced(file).then(result => 
          setStateImage({
            customImg: result,
          }),
        );
      }

      readFile(file);
    }
  };


  const getRealDimensions = (image) => {
    if (image != null) {
      let uimg = new Image();
      uimg.src = image; // stateObjImage.customImg
      uimg.decode().then(() => {
          let decWidth = uimg.width;
          let decHeight = uimg.height;

          setStateDims({
            width: decWidth,
            height: decHeight,
          });

          const getCanvasDimensions = () => {
            // Width is determined by the css value for the viewport width this is then respected by the device pixel ratio. This is then used to set the canvas.width value
            var _width = Math.round((Number(stateDims.width) / devicePixelRatio) * devicePixelRatio);

            // height is determined by the css value for the viewport height this is then respected by the device pixel ratio. This is then used to set the canvas.height value
            var _height = Math.round((Number(stateDims.height) / devicePixelRatio) * devicePixelRatio);
          }

          getCanvasDimensions();
      });
    }
  }

  useEffect(() => {
    getRealDimensions(stateObjImage.customImg); // This will be executed when the state changes
  }, [stateObjImage]);


  let saveableCanvas = null;
  let loadableCanvas = null


  return (
    <section className={`${style.teamSection} ${daynight ? style.nightMode : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">      
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    handleImageUpload(e);
                  }}                  
                  ref={imageUploader}
                  style={{
                    display: "none"
                  }}
                />
                <div
                  style={{
                    height: "50%",
                    width: "50%",
                    border: "1px dashed black"
                  }}
                  onClick={() => imageUploader.current.click()}
                >
                  <img
                    ref={uploadedImage}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "acsolute"
                    }}
                  />
                </div>
                Click to upload Image
              </div>
              
              <h1>color: {stateObj.color} :: {initStateObj.customImg}</h1>

              <h2>Custom Preview Background Image</h2>
              <div className={style.tools}>
                <button
                  onClick={() => {
                    localStorage.setItem(
                      "savedDrawing",
                      saveableCanvas.getSaveData()
                    );
                  }}
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    saveableCanvas.eraseAll();
                  }}
                >
                  Erase
                </button>
                <button
                  onClick={() => {
                    saveableCanvas.undo();
                  }}
                >
                  Undo
                </button>
                <button
                  onClick={() => {
                    console.log(saveableCanvas.getDataURL());
                    alert("DataURL written to console")
                  }}
                >
                  GetDataURL
                </button>
                <div>
                  <label>Width:</label>
                  <input
                    type="number"
                    value={stateObj.width}
                    onChange={e =>
                      setState({ width: parseInt(e.target.value, 10) })
                    }
                  />
                </div>
                <div>
                  <label>Height:</label>
                  <input
                    type="number"
                    value={stateObj.height}
                    onChange={e =>
                      setState({ height: parseInt(e.target.value, 10) })
                    }
                  />
                </div>
                <div>
                  <label>Brush-Radius:</label>
                  <input
                    type="number"
                    value={stateObj.brushRadius}
                    onChange={e =>
                      setState({ brushRadius: parseInt(e.target.value, 10) })
                    }
                  />
                </div>
                <div>
                  <label>Lazy-Radius:</label>
                  <input
                    type="number"
                    value={stateObj.lazyRadius}
                    onChange={e =>
                      setState({ lazyRadius: parseInt(e.target.value, 10) })
                    }
                  />
                </div>
              </div>
              {/*brushColor={stateObj.color}*/}
       
              {stateDims.height != null &&
                <>
                  <h2>
                    You have {stateDims.height} unread messages.
                  </h2>
                  <div 
                  className={`container`}
                  style={{ position: "relative",
                                backgroundColor: "green",
                                color: "white",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                objectFit: "contain",
                                margin: "0 0 0 0",
                                maxHeight: "100%",
                                maxWidth: "100%",
                                minWidth: "0",
                                minHeight: "0",
                                overflowX: "scroll",
                                overflowY: "scroll",
                              }}>
                    <CanvasDraw
                      imgSrc={stateObjImage.customImg}

                      enablePanAndZoom
                      clampLinesToDocument

                      ref={canvasDraw => (saveableCanvas = canvasDraw)}
                      brushColor="rgba(155,12,60,0.3)"
                      brushRadius={stateObj.brushRadius}
                      lazyRadius={stateObj.lazyRadius}
                      canvasWidth={stateDims.width}
                      canvasHeight={stateDims.height}
                      zoomExtents={ {min: 0.33, max: 3} }
                    />
                  </div>
                </>
              }

              <div>{stateObj.reduxState}</div>
              <p>
                The following is a disabled canvas with a hidden grid that we use to
                load & show your saved drawing.
              </p>
              <button
                onClick={() => {
                  loadableCanvas.loadSaveData(
                    localStorage.getItem("savedDrawing")
                  );
                }}
              >
                Load what you saved previously into the following canvas. Either by
                calling `loadSaveData()` on the component's reference or passing it
                the `saveData` prop:
              </button>

              {stateDims.height != null &&
                <>
                  <h2>
                    You have {stateDims.height} unread messages.
                  </h2>
                  <div 
                  className={`container`}
                  style={{ position: "relative",
                                backgroundColor: "green",
                                color: "white",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                objectFit: "contain",
                                margin: "0 0 0 0",
                                maxHeight: "100%",
                                maxWidth: "100%",
                              }}>
                    <CanvasDraw
                      disabled
                      hideGrid
                      ref={canvasDraw => (loadableCanvas = canvasDraw)}
                      saveData={localStorage.getItem("savedDrawing")}
                    />
                  </div>
                </>
              }

              <p>
                The saving & loading also takes different dimensions into account.
                Change the width & height, draw something and save it and then load it
                into the disabled canvas. It will load your previously saved
                masterpiece scaled to the current canvas dimensions.
              </p>
            </div>
          </div>
        </div>
      </div>

    </section>
	);
}
