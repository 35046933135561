import React from "react";
import style from "../styles/teamSection.module.css";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";

export default function HeroSectionSlider() {
  const d = useTranslation();
  const daynight = useSelector((state) => state.data.dayNight);
  return (
    <div
      className={`mt-5 ${style.teamCarousel} ${
        daynight ? style.nightMode : ""
      }`}
    >
      <div
        id="heroSectionSlider"
        className={`carousel slide logoCarousel mt-3 ${style.teamSlider}`}
        data-ride="carousel"
      >
        <ol className={`carousel-indicators ${style.carousel_indicators}`}>
          <li
            data-target="#heroSectionSlider"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#heroSectionSlider" data-slide-to="1"></li>
          <li data-target="#heroSectionSlider" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner" role="listbox">
          <div className="carousel-item active">
            <img
              className={style.sliderImage}
              src="/images/slider-logo.jpg"
              alt=""
            />
            <div className={style.commonText}>
              {d("team.slides.slide1.description2")}
            </div>
            <div className={style.userArea}>
              <img
                className={style.userImage}
                src="/images/slider-img.jpg"
                alt=""
              />
              <div>
                <div className={style.userName}>
                  {d("team.slides.slide1.title")}
                </div>
                <div className={style.userText}>
                  {d("team.slides.slide1.description")}
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              className={style.sliderImage}
              src="/images/slider-logo.jpg"
              alt=""
            />
            <div className={style.commonText}>
              {d("team.slides.slide2.description2")}
            </div>
            <div className={style.userArea}>
              <img
                className={style.userImage}
                src="/images/slider-img.jpg"
                alt=""
              />
              <div>
                <div className={style.userName}>
                  {d("team.slides.slide2.title")}
                </div>
                <div className={style.userText}>
                  {d("team.slides.slide2.description")}
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              className={style.sliderImage}
              src="/images/slider-logo.jpg"
              alt=""
            />
            <div className={style.commonText}>
              {" "}
              {d("team.slides.slide3.description2")}
            </div>
            <div className={style.userArea}>
              <img
                className={style.userImage}
                src="/images/slider-img.jpg"
                alt=""
              />
              <div>
                <div className={style.userName}>
                  {d("team.slides.slide3.title")}
                </div>
                <div className={style.userText}>
                  {d("team.slides.slide3.description")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          href="#heroSectionSlider"
          className="carousel-control-prev d-none"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon"></span>
        </a>

        <a
          href="#heroSectionSlider"
          className="carousel-control-next d-none"
          data-slide="next"
        >
          <span className="carousel-control-next-icon"></span>
        </a>
      </div>
    </div>
  );
}
